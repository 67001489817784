import { Grid, Box } from "@mui/material";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CardLink from "components/card/CardLink";
import SecondTop from "components/top/SecondTop";
import ServiceAlert from "components/common/ServiceAlert";

function OrganizationsTop() {
  const { t, i18n } = useTranslation();
  const { lang } = useParams();
  useEffect(() => {
    if (lang === "en") {
      i18n.changeLanguage("en");
    } else {
      i18n.changeLanguage("ja");
    }
  }, [lang, i18n]);
  return (
    <>
      <ServiceAlert />
      <Box className="cmTop">
        <Grid
          container
          rowSpacing={2}
          columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 4, xl: 5 }}
          justifyContent="center"
          className="Top-row1"
        >
          <Grid item xs={10} sm={6} md={6} lg={6} xl={6}>
            <CardLink
              href="/organizations/join"
              icon={<AccountCircleIcon fontSize="large" />}
              title={t("organizationsCardText")}
              subTitle={t("joinOrganizationsCardTextSecondary")}
            />
          </Grid>
          <Grid item xs={10} sm={4} md={4} lg={4} xl={4}>
            <CardLink
              href="/organizations/member"
              icon={<AddCircleOutlineIcon fontSize="large" />}
              title={t("addOrganizationsMemberCardText")}
              subTitle={t("addOrganizationsCardTextSecondary")}
            />
          </Grid>
        </Grid>
        <SecondTop />
      </Box>
    </>
  );
}

export default OrganizationsTop;
