import * as Yup from "yup";
import { useTranslation } from "react-i18next";

export function useAddBillingSchema() {
  const { t } = useTranslation();
  return Yup.object().shape({
    billingMethod: Yup.string().oneOf(
      ["existingProject"],
      t("errorSelectBillingMethod")
    ),
    projectId: Yup.string().when("billingMethod", {
      is: (val) => val === "existingProject",
      then: Yup.string().required(t("errorRequired")),
      otherwise: Yup.string().notRequired(),
    }),
    projectName: Yup.string().when("billingMethod", {
      is: (val) => val === "newProject",
      then: Yup.string()
        .required(t("errorRequired"))
        .max(32, t("errorProjectNameMaxLimit"))
        .matches(/^[a-zA-Z0-9!-/:-@¥[-`{-~ ]*$/, t("errorProjectNameFormat")),
      otherwise: Yup.string().notRequired(),
    }),
    billingOption: Yup.string().when("billingMethod", {
      is: (val) => val === "newProject",
      then: Yup.string().oneOf(
        ["emailOnly", "invoiceShipping"],
        t("errorSelectBillingOption")
      ),
      otherwise: Yup.string().notRequired(),
    }),
    paymentMethod: Yup.string().when("billingMethod", {
      is: (val) => val === "newProject",
      then: Yup.string().oneOf(["bankTransfer"], t("errorSelectPaymentMethod")),
      otherwise: Yup.string().notRequired(),
    }),
    subscriptionPlan: Yup.string().when("billingMethod", {
      is: (val) => val === "newProject",
      then: Yup.string()
        .required(t("errorRequired"))
        .oneOf(
          ["V2_MEMBERS", "V2_PREMIUM", "START", "MEMBERS"],
          t("errorSelectSubscriptionPlan")
        ),
      otherwise: Yup.string().notRequired(),
    }),
    paymentCurrency: Yup.string().when("subscriptionPlan", {
      is: (val) => val === "V2_MEMBERS" || val === "START",
      then: Yup.string().oneOf(["USD", "JPY"], t("errorSelectPaymentCurrency")),
      otherwise: Yup.string().notRequired(),
    }),
    invoiceLanguage: Yup.string().when("billingMethod", {
      is: (val) => val === "newProject",
      then: Yup.string().oneOf(["en", "ja"], t("errorSelectInvoiceLanguage")),
      otherwise: Yup.string().notRequired(),
    }),
    invoicePersonFirstName: Yup.string().when("billingMethod", {
      is: (val) => val === "newProject",
      then: Yup.string().required(t("errorRequired")),
      otherwise: Yup.string().notRequired(),
    }),
    invoicePersonLastName: Yup.string().when("billingMethod", {
      is: (val) => val === "newProject",
      then: Yup.string().required(t("errorRequired")),
      otherwise: Yup.string().notRequired(),
    }),
    invoiceMailAddress: Yup.string().when("billingMethod", {
      is: (val) => val === "newProject",
      then: Yup.string().required(t("errorRequired")).email(t("errorEmail")),
      otherwise: Yup.string().notRequired(),
    }),
    invoiceZipCode: Yup.string().when("billingOption", {
      is: (val) => val === "invoiceShipping",
      then: Yup.string()
        .required(t("errorRequired"))
        .matches(/^[0-9]{7,7}$/, t("errorZipCodeFormat")),
      otherwise: Yup.string().notRequired(),
    }),
    invoiceAddressCountry: Yup.string().when("billingOption", {
      is: (val) => val === "invoiceShipping",
      then: Yup.string().required(t("errorRequired")),
      otherwise: Yup.string().notRequired(),
    }),
    invoiceAddressState: Yup.string().when("billingOption", {
      is: (val) => val === "invoiceShipping",
      then: Yup.string().required(t("errorRequired")),
      otherwise: Yup.string().notRequired(),
    }),
    invoiceAddressCity: Yup.string().when("billingOption", {
      is: (val) => val === "invoiceShipping",
      then: Yup.string().required(t("errorRequired")),
      otherwise: Yup.string().notRequired(),
    }),
    invoiceAddressStreet: Yup.string().when("billingOption", {
      is: (val) => val === "invoiceShipping",
      then: Yup.string().required(t("errorRequired")),
      otherwise: Yup.string().notRequired(),
    }),
  });
}

export function useJoinBillingSchema() {
  const { t } = useTranslation();
  return Yup.object().shape({
    billingOption: Yup.string().oneOf(
      ["emailOnly", "invoiceShipping"],
      t("errorSelectBillingOption")
    ),
    paymentMethod: Yup.string().oneOf(
      ["bankTransfer"],
      t("errorSelectPaymentMethod")
    ),
    projectName: Yup.string()
      .required(t("errorRequired"))
      .max(32, t("errorProjectNameMaxLimit"))
      .matches(/^[a-zA-Z0-9!-/:-@¥[-`{-~ ]*$/, t("errorProjectNameFormat")),
    subscriptionPlan: Yup.string()
      .required(t("errorRequired"))
      .oneOf(
        ["V2_MEMBERS", "V2_PREMIUM", "START", "MEMBERS"],
        t("errorSelectSubscriptionPlan")
      ),
    paymentCurrency: Yup.string().when("subscriptionPlan", {
      is: (val) => val === "V2_MEMBERS" || val === "START",
      then: Yup.string().oneOf(["USD", "JPY"], t("errorSelectPaymentCurrency")),
      otherwise: Yup.string().notRequired(),
    }),
    invoiceLanguage: Yup.string().oneOf(
      ["en", "ja"],
      t("errorSelectInvoiceLanguage")
    ),
    invoicePersonFirstName: Yup.string().required(t("errorRequired")),
    invoicePersonLastName: Yup.string().required(t("errorRequired")),
    invoiceMailAddress: Yup.string()
      .required(t("errorRequired"))
      .email(t("errorEmail")),
    invoiceZipCode: Yup.string().when("billingOption", {
      is: (val) => val === "invoiceShipping",
      then: Yup.string()
        .required(t("errorRequired"))
        .matches(/^[0-9]{7,7}$/, t("errorZipCodeFormat")),
      otherwise: Yup.string().notRequired(),
    }),
    invoiceAddressCountry: Yup.string().when("billingOption", {
      is: (val) => val === "invoiceShipping",
      then: Yup.string().required(t("errorRequired")),
      otherwise: Yup.string().notRequired(),
    }),
    invoiceAddressState: Yup.string().when("billingOption", {
      is: (val) => val === "invoiceShipping",
      then: Yup.string().required(t("errorRequired")),
      otherwise: Yup.string().notRequired(),
    }),
    invoiceAddressCity: Yup.string().when("billingOption", {
      is: (val) => val === "invoiceShipping",
      then: Yup.string().required(t("errorRequired")),
      otherwise: Yup.string().notRequired(),
    }),
    invoiceAddressStreet: Yup.string().when("billingOption", {
      is: (val) => val === "invoiceShipping",
      then: Yup.string().required(t("errorRequired")),
      otherwise: Yup.string().notRequired(),
    }),
  });
}
