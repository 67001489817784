import { Grid } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import React, { useContext, useEffect } from "react";
import TextField from "@mui/material/TextField";
import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import { UserInputData } from "./Content";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import CustomTooltip from "components/common/CustomTooltip";

const getOtherSchema = ({ t }) => {
  return Yup.object().shape({
    campaignCode: Yup.string().matches(
      /^[A-Z0-9]*$/,
      t("errorCampaignCodeFormat")
    ),
  });
};

function Others(props) {
  const { t } = useTranslation();
  const otherSchema = getOtherSchema({ t });
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      salesPersonName: "",
      campaignCode: "",
      remarksInfomation: "",
    },
    resolver: yupResolver(otherSchema),
  });
  const { currentState, setCurrentState, isChecking } =
    useContext(UserInputData);

  const others = currentState["Others"];
  useEffect(() => {
    if (others) {
      for (const k of Object.keys(getValues())) {
        setValue(k, others[k], { shouldValidate: true });
      }
    }
  }, [others, getValues, setValue]);

  const onSubmit = (action) => {
    switch (action) {
      case "back":
        props.handleBack();
        break;
      case "ok":
        props.handleConfirm();
        break;
      default:
        props.handleNext();
    }
    const data = getValues();
    setCurrentState({ ...currentState, Others: data });
  };
  return (
    <>
      <Typography gutterBottom variant="h4" component="h2">
        {t("othersLabel")}
      </Typography>
      <Typography variant="body2" color="textSecondary">
        {t("othersDescription")}
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          name="salesPersonName"
          render={({ field }) => (
            <TextField
              {...field}
              label={t("salesPersonNameLabel")}
              fullWidth
              margin="normal"
              variant="outlined"
            />
          )}
        />
        <CustomTooltip title={t("salesPersonNameTooltipTitle")} />
        <Controller
          control={control}
          name="campaignCode"
          render={({ field }) => (
            <TextField
              {...field}
              label={t("campaignCodeLabel")}
              error={errors.campaignCode ? true : false}
              helperText={errors.campaignCode?.message}
              fullWidth
              margin="normal"
              variant="outlined"
            />
          )}
        />
        <CustomTooltip title={t("campaignCodeTooltipTitle")} />
        <Controller
          control={control}
          name="remarksInfomation"
          render={({ field }) => (
            <TextField
              {...field}
              label={t("remarksInfomationLabel")}
              fullWidth
              margin="normal"
              rows={4}
              multiline
              variant="outlined"
              placeholder={t("remarksInfomationPlaceholder")}
            />
          )}
        />
        <Box mt={4} className="mb60">
          {isChecking ? (
            <Grid container spacing={1} justifyContent="flex-end">
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  type="button"
                  onClick={() => onSubmit("ok")}
                  disabled={!isValid}
                >
                  {t("okButtonLabel")}
                </Button>
              </Grid>
            </Grid>
          ) : (
            <Grid container spacing={1} justifyContent="space-between">
              <Grid item>
                <Button
                  variant="contained"
                  color="inherit"
                  onClick={() => onSubmit("back")}
                  disabled={!isValid}
                >
                  {t("backButtonLabel")}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={!isValid}
                >
                  {t("nextButtonLabel")}
                </Button>
              </Grid>
            </Grid>
          )}
        </Box>
      </form>
    </>
  );
}

export default Others;
