import {
  TextField,
  Box,
  Typography,
  FormControl,
  FormHelperText,
  ListItemIcon,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import React, { useContext, useEffect } from "react";
import { UserInputData } from "./Content";
import { useTranslation } from "react-i18next";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import enLocale from "date-fns/locale/en-US";
import jaLocale from "date-fns/locale/ja";
import { format, isValid as isDateValid } from "date-fns";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { FormProvider, RHFTextField } from "components/hook-form";
import FormHeader from "components/common/FormHeader";
import PaginationBox from "components/common/PaginationBox";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDesiredMonthSchema } from "schemas/Others";

const defaultValues = {
  desiredMonth: null,
  salesPersonName: "",
  remarksInfomation: "",
};

const localeMap = {
  en: enLocale,
  ja: jaLocale,
};

function Others(props) {
  const { t } = useTranslation();
  const { desiredMonthSchema, nextMonth, nextYear } = useDesiredMonthSchema({
    t,
  });
  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(desiredMonthSchema),
    defaultValues,
  });
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { isValid, errors },
  } = methods;
  const { currentState, setCurrentState, isChecking } =
    useContext(UserInputData);

  const others = currentState["Others"];
  useEffect(() => {
    if (others) {
      for (const k of Object.keys(getValues())) {
        setValue(k, others[k], { shouldValidate: true });
      }
      if (!others?.desiredMonth) {
        setValue("desiredMonth", null, {
          shouldValidate: true,
        });
      } else {
        const d = new Date(others.desiredMonth);
        setValue("desiredMonth", d, {
          shouldValidate: true,
        });
      }
    }
  }, [others, getValues, setValue]);

  const onSubmit = (action) => {
    switch (action) {
      case "back":
        props.handleBack();
        break;
      case "ok":
        props.handleConfirm();
        break;
      default:
        props.handleNext();
    }
    const data = getValues();
    if (isDateValid(data?.desiredMonth)) {
      data.desiredMonth = format(data.desiredMonth, "yyyy/MM");
    } else {
      data.desiredMonth = "";
    }
    setCurrentState({ ...currentState, Others: data });
  };
  return (
    <>
      <FormHeader label={t("othersLabel")} />
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          name="desiredMonth"
          render={({ field }) => (
            <FormControl fullWidth={true} error>
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={localeMap["ja"]}
              >
                <DesktopDatePicker
                  label={t("desiredMonthLabel")}
                  mask="____/__"
                  inputFormat="yyyy/MM"
                  value={field.value}
                  views={["year", "month"]}
                  openTo="year"
                  renderInput={(params) => <TextField {...params} />}
                  onChange={(newValue) => {
                    if (!newValue) {
                      setValue("desiredMonth", null, {
                        shouldValidate: true,
                      });
                    }
                    if (isDateValid(newValue)) {
                      setValue("desiredMonth", newValue, {
                        shouldValidate: true,
                      });
                    }
                  }}
                  minDate={nextMonth}
                  maxDate={nextYear}
                />
              </LocalizationProvider>
              <FormHelperText>{errors.desiredMonth?.message}</FormHelperText>
            </FormControl>
          )}
        />
        <Box>
          <ListItemIcon>
            <ErrorOutlineIcon />
            <Typography sx={{ mt: 0.3, ml: 0.3 }} variant="body2">
              {t("desiredMonthNote")}
            </Typography>
          </ListItemIcon>
        </Box>
        <RHFTextField
          name="salesPersonName"
          label={t("salesPersonNameLabel")}
        />
        <RHFTextField
          name="remarksInfomation"
          label={t("remarksInfomationLabel")}
          placeholder={t("remarksInfomationPlaceholder")}
          rows={4}
          multiline
        />
        <PaginationBox
          isChecking={isChecking}
          isValid={isValid}
          onSubmit={onSubmit}
        />
      </FormProvider>
    </>
  );
}

export default Others;
