import { useForm, Controller, useWatch } from "react-hook-form";
import React, { useContext, useState, useEffect } from "react";
import { Grid, Button, Link, Box } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { UserInputData } from "./Content";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import ListItemIcon from "@mui/material/ListItemIcon";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";

function Agreement(props) {
  const { t } = useTranslation();
  const { control, handleSubmit } = useForm({
    defaultValues: {
      termOfUse: false,
      handlingPersonalInformation: false,
    },
  });
  const { currentState, setCurrentState } = useContext(UserInputData);
  const [displayNext, setDisplayNext] = useState(false);
  const watchHandlingPersonalInformation = useWatch({
    control,
    name: "handlingPersonalInformation",
  });
  const watchTermOfUse = useWatch({ control, name: "termOfUse" });
  useEffect(() => {
    if (watchHandlingPersonalInformation && watchTermOfUse) {
      setDisplayNext(true);
    } else {
      setDisplayNext(false);
    }
  }, [watchHandlingPersonalInformation, watchTermOfUse]);
  const onSubmit = (data) => {
    props.handleNext();
    setCurrentState({ ...currentState, Agreement: data });
  };
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Typography gutterBottom variant="h4" component="h2">
          {t("agreementLabel")}
        </Typography>
        <Typography variant="p" component="p">
          {t("agreementDescription")}
        </Typography>
        <Box>
          <ListItemIcon>
            <ChevronRightOutlinedIcon />
            <Link
              target="_blank"
              href="https://docs.classmethod.jp/tos/cm_tos_members_terms_en.pdf"
            >
              {t("tosLinkLabel")}
            </Link>
          </ListItemIcon>
        </Box>
        <Box>
          <ListItemIcon>
            <ChevronRightOutlinedIcon />
            <Link target="_blank" href="https://classmethod.jp/privacy/">
              {t("ppLinkLabel")}
            </Link>
          </ListItemIcon>
        </Box>
        <Box>
          <Controller
            control={control}
            name="termOfUse"
            render={({ field: { value, onChange } }) => (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={value}
                    onChange={onChange}
                    color="primary"
                  />
                }
                label={t("termOfUseLabel")}
              />
            )}
          />
        </Box>
        <Box>
          <Controller
            control={control}
            name="handlingPersonalInformation"
            render={({ field: { value, onChange } }) => (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={value}
                    onChange={onChange}
                    color="primary"
                  />
                }
                label={t("handlingPersonalInformationLabel")}
              />
            )}
          />
        </Box>
        <Box mt={4} className="mb60">
          <Grid container spacing={1} justifyContent="flex-end">
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={!displayNext}
              >
                {t("nextButtonLabel")}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </form>
    </>
  );
}

export default Agreement;
