import {
  Grid,
  TextField,
  Button,
  MenuItem,
  Box,
  Typography,
} from "@mui/material";
import { useForm, Controller, useWatch } from "react-hook-form";
import React, { useContext, useEffect } from "react";
import { UserInputData } from "./Content";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import CustomTooltip from "components/common/CustomTooltip";

const getTransfereeSchema = ({ t }) => {
  return Yup.object().shape({
    cancelCompanyName: Yup.string().required(t("errorRequired")),
    adminName: Yup.string().required(t("errorRequired")),
    cancelAdminAddress: Yup.string()
      .required(t("errorRequired"))
      .email(t("errorEmail")),
    trasferDestination: Yup.string().oneOf(
      ["myCompany", "otherCompany"],
      t("errorSelectTrasferDestination")
    ),
  });
};

function Transferee(props) {
  const { t } = useTranslation();
  const transfereeSchema = getTransfereeSchema({ t });
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      cancelCompanyName: "",
      adminName: "",
      cancelAdminAddress: "",
      trasferDestination: "",
    },
    resolver: yupResolver(transfereeSchema),
  });

  const watchAccountRole = useWatch({ control, name: "accountRole" });
  useEffect(() => {
    if (watchAccountRole === "memberAccount") {
    } else {
    }
  }, [watchAccountRole]);

  const { currentState, setCurrentState, isChecking } =
    useContext(UserInputData);

  const transfer = currentState["Transferee"];
  useEffect(() => {
    if (transfer) {
      for (const k of Object.keys(getValues())) {
        setValue(k, transfer[k], { shouldValidate: true });
      }
    }
  }, [transfer, getValues, setValue]);
  const onSubmit = (action) => {
    if (action === "back") {
      props.handleBack();
    } else if (isChecking) {
      props.handleConfirm();
    } else {
      props.handleNext();
    }
    const data = getValues();
    setCurrentState({ ...currentState, Transferee: data });
  };
  return (
    <>
      <Typography gutterBottom variant="h4" component="h2">
        {t("transfereeLabel")}
      </Typography>
      <Typography variant="body2" color="textSecondary">
        {t("transferDescription")}
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          name="cancelCompanyName"
          render={({ field }) => (
            <TextField
              {...field}
              label={t("cancelCompanyNameLabel")}
              error={errors.cancelCompanyName ? true : false}
              helperText={errors.cancelCompanyName?.message}
              fullWidth
              margin="normal"
              placeholder={t("companyNamePlaceholder")}
            />
          )}
        />
        <Controller
          control={control}
          name="adminName"
          render={({ field }) => (
            <TextField
              {...field}
              label={t("adminNameLabel")}
              error={errors.adminName ? true : false}
              helperText={errors.adminName?.message}
              fullWidth
              margin="normal"
            />
          )}
        />
        <Controller
          control={control}
          name="cancelAdminAddress"
          render={({ field }) => (
            <TextField
              {...field}
              label={t("cancelAdminAddressLabel")}
              error={errors.cancelAdminAddress ? true : false}
              helperText={errors.cancelAdminAddress?.message}
              fullWidth
              margin="normal"
              placeholder={t("adminAddressPlaceholder")}
            />
          )}
        />
        <CustomTooltip title={t("cancelAdminAddressTooltipTitle")} />
        <Controller
          control={control}
          name="trasferDestination"
          render={({ field }) => (
            <TextField
              {...field}
              label={t("trasferDestinationLabel")}
              fullWidth
              margin="normal"
              error={errors.trasferDestination ? true : false}
              helperText={errors.trasferDestination?.message}
              id="trasferDestination"
              select
            >
              <MenuItem value="myCompany">{t("myCompanyLabel")}</MenuItem>
              <MenuItem value="otherCompany">{t("otherCompanyLabel")}</MenuItem>
            </TextField>
          )}
        />
        <Box mt={4} className="mb60">
          {isChecking ? (
            <Grid container spacing={1} justifyContent="flex-end">
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  type="button"
                  onClick={() => onSubmit("ok")}
                  disabled={!isValid}
                >
                  {t("okButtonLabel")}
                </Button>
              </Grid>
            </Grid>
          ) : (
            <Grid container spacing={1} justifyContent="space-between">
              <Grid item>
                <Button
                  variant="contained"
                  color="inherit"
                  onClick={() => onSubmit("back")}
                >
                  {t("backButtonLabel")}
                </Button>
              </Grid>
              <Grid item>
                <Button variant="contained" color="primary" type="submit">
                  {t("nextButtonLabel")}
                </Button>
              </Grid>
            </Grid>
          )}
        </Box>
      </form>
    </>
  );
}

export default Transferee;
