import HelpIcon from "@mui/icons-material/Help";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

const CustomInfoTooltip = styled(({ className, ...props }) => (
  <div style={{ position: "relative" }}>
    <Tooltip
      title={props.title}
      arrow
      placement="top-start"
      classes={{ popper: className }}
    >
      <HelpIcon
        color="action"
        style={{
          position: "absolute",
          left: -35,
          top: props.top || -45, // Default is -45
          width: 35,
          height: 20,
        }}
      />
    </Tooltip>
  </div>
))({
  [`& .${tooltipClasses.tooltip}`]: {
    fontSize: 16,
    maxWidth: 400,
  },
});

function CustomTooltip(props) {
  return <CustomInfoTooltip title={props.title} top={props.top} />;
}

export default CustomTooltip;
