import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import SubCardLink from "components/card/SubCardLink";

function SecondTop() {
  const { t } = useTranslation();
  return (
    <>
      <Grid
        container
        rowSpacing={2}
        columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 4, xl: 5 }}
        justifyContent="center"
        className="Top-row2"
      >
        <Grid item xs={10} sm={5} md={5} lg={5} xl={5}>
          <SubCardLink
            href="https://helpdesk.classmethod.net/hc/articles/10117932228889"
            title={t("modifyContractCardTitle")}
          />
        </Grid>
        <Grid item xs={10} sm={5} md={5} lg={5} xl={5}>
          <SubCardLink
            href="https://classmethod.jp/cases/category/aws/"
            title={t("caseStudyCardTitle")}
          />
        </Grid>
      </Grid>
    </>
  );
}
export default SecondTop;
