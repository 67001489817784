import { Grid } from "@mui/material";
import { useForm, Controller, useWatch } from "react-hook-form";
import React, { useContext, useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import { Button, MenuItem } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import { UserInputData } from "./Content";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import CustomTooltip from "components/common/CustomTooltip";

const getAccountSchema = ({ t }) => {
  return Yup.object().shape({
    subscriptionType: Yup.string()
      .required(t("errorRequired"))
      .oneOf(
        ["newAccount", "transferAccount"],
        t("errorSelectSubscriptionType")
      ),
    accountId: Yup.string().when("subscriptionType", {
      is: (val) => val === "transferAccount",
      then: Yup.string()
        .required(t("errorRequired"))
        .min(12, t("errorAwsAccountFormat"))
        .max(12, t("errorAwsAccountFormat"))
        .matches(/^[0-9]{12}$/, t("errorAwsAccountFormat")),
      otherwise: Yup.string().notRequired(),
    }),
    accountName: Yup.string()
      .trim()
      .required(t("errorRequired"))
      .min(2, t("errorAccountNameMinLimit"))
      .max(50, t("errorAccountNameMaxLimit"))
      .matches(
        /^[a-zA-Z0-9!-/:-@¥[-`{-~ ]*$/,
        t("errorMatchAccountNameFormat")
      ),
    techAddress: Yup.string()
      .required(t("errorRequired"))
      .email(t("errorEmail")),
    securityAddress: Yup.string()
      .required(t("errorRequired"))
      .email(t("errorEmail")),
    billingAddress: Yup.string()
      .required(t("errorRequired"))
      .email(t("errorEmail")),
    payerAccount: Yup.string()
      .required(t("errorRequired"))
      .min(12, t("errorAwsAccountFormat"))
      .max(12, t("errorAwsAccountFormat"))
      .matches(/^[0-9]{12}$/, t("errorAwsAccountFormat")),
    enableCloudTrail: Yup.boolean().oneOf([true], t("errorAgreement")),
    enableAwsConfig: Yup.boolean().oneOf([true], t("errorAgreement")),
  });
};

function Account(props) {
  const { t } = useTranslation();
  const accountSchema = getAccountSchema({ t });
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      subscriptionType: "",
      accountName: "",
      techAddress: "",
      securityAddress: "",
      billingAddress: "",
      payerAccount: "",
      enableCloudTrail: false,
      enableAwsConfig: false,
    },
    resolver: yupResolver(accountSchema),
  });
  const { currentState, setCurrentState, isChecking } =
    useContext(UserInputData);
  const [isTransfer, setIsTransfer] = useState(false);

  const watchSubscriptionType = useWatch({ control, name: "subscriptionType" });
  useEffect(() => {
    if (watchSubscriptionType === "transferAccount") {
      setIsTransfer(true);
    } else {
      setValue("accountId", "", { shouldValidate: true });
      setIsTransfer(false);
    }
  }, [watchSubscriptionType, setValue]);

  const account = currentState["Account"];
  useEffect(() => {
    if (account) {
      for (const k of Object.keys(getValues())) {
        setValue(k, account[k], { shouldValidate: true });
      }
    }
  }, [account, getValues, setValue]);

  const onSubmit = (action) => {
    switch (action) {
      case "back":
        props.handleBack();
        break;
      case "ok":
        props.handleConfirm();
        break;
      default:
        props.handleNext();
    }
    const data = getValues();
    setCurrentState({ ...currentState, Account: data });
  };
  return (
    <>
      <Typography gutterBottom variant="h4" component="h2">
        {t("accountLabel")}
      </Typography>
      <Typography variant="body2" color="textSecondary">
        {t("accountDescription")}
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          name="subscriptionType"
          render={({ field }) => (
            <TextField
              {...field}
              label={t("subscriptionTypeLabel")}
              fullWidth
              margin="normal"
              error={errors.subscriptionType ? true : false}
              helperText={errors.subscriptionType?.message}
              id="subscriptionType"
              select
            >
              <MenuItem value="newAccount">{t("newAccountLabel")}</MenuItem>
              <MenuItem value="transferAccount">
                {t("transferAccountLabel")}
              </MenuItem>
            </TextField>
          )}
        />
        {isTransfer ? (
          <>
            <Controller
              control={control}
              name="accountId"
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t("accountIdLabel")}
                  error={errors.accountId ? true : false}
                  helperText={errors.accountId?.message}
                  fullWidth
                  margin="normal"
                />
              )}
            />
            <Box>
              <Typography variant="body2" color="textSecondary">
                {t("accountIdDescription")}
              </Typography>
            </Box>
          </>
        ) : (
          ""
        )}
        <Controller
          control={control}
          name="accountName"
          render={({ field }) => (
            <TextField
              {...field}
              label={t("accountNameLabel")}
              error={errors.accountName ? true : false}
              helperText={errors.accountName?.message}
              fullWidth
              margin="normal"
            />
          )}
        />
        <Controller
          control={control}
          name="techAddress"
          render={({ field }) => (
            <TextField
              {...field}
              label={t("techAddressLabel")}
              error={errors.techAddress ? true : false}
              helperText={errors.techAddress?.message}
              type="email"
              fullWidth
              margin="normal"
              placeholder={t("techAddressPlaceholder")}
            />
          )}
        />
        <CustomTooltip title={t("techAddressTooltipTitle")} />
        <Controller
          control={control}
          name="securityAddress"
          render={({ field }) => (
            <TextField
              {...field}
              label={t("securityAddressLabel")}
              type="email"
              error={errors.securityAddress ? true : false}
              helperText={errors.securityAddress?.message}
              fullWidth
              margin="normal"
              placeholder={t("securityAddressPlaceholder")}
            />
          )}
        />
        <CustomTooltip title={t("securityAddressTooltipTitle")} />
        <Controller
          control={control}
          name="billingAddress"
          render={({ field }) => (
            <TextField
              {...field}
              label={t("billingAddressLabel")}
              type="email"
              error={errors.billingAddress ? true : false}
              helperText={errors.billingAddress?.message}
              fullWidth
              margin="normal"
              placeholder={t("billingAddressPlaceholder")}
            />
          )}
        />
        <CustomTooltip title={t("billingAddressTooltipTitle")} />
        <Controller
          control={control}
          name="payerAccount"
          render={({ field }) => (
            <TextField
              {...field}
              label={t("payerAccountLabel")}
              error={errors.payerAccount ? true : false}
              helperText={errors.payerAccount?.message}
              fullWidth
              margin="normal"
            />
          )}
        />
        <Controller
          control={control}
          name="enableCloudTrail"
          render={({ field: { value, onChange } }) => (
            <FormControl error>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={value}
                    onChange={onChange}
                    color="primary"
                  />
                }
                label={t("enableCloudTrailLabel")}
              />
              <FormHelperText>
                {errors.enableCloudTrail?.message}
              </FormHelperText>
            </FormControl>
          )}
        />
        <CustomTooltip title={t("enableCloudTrailTooltipTitle")} top={-35} />
        <Controller
          control={control}
          name="enableAwsConfig"
          render={({ field: { value, onChange } }) => (
            <FormControl error>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={value}
                    onChange={onChange}
                    color="primary"
                  />
                }
                label={t("enableAwsConfigLabel")}
              />
              <FormHelperText>{errors.enableAwsConfig?.message}</FormHelperText>
            </FormControl>
          )}
        />
        <CustomTooltip title={t("enableAwsConfigTooltipTitle")} top={-35} />
        <Box mt={4} className="mb60">
          {isChecking ? (
            <Grid container spacing={1} justifyContent="flex-end">
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  type="button"
                  onClick={() => onSubmit("ok")}
                  disabled={!isValid}
                >
                  {t("okButtonLabel")}
                </Button>
              </Grid>
            </Grid>
          ) : (
            <Grid container spacing={1} justifyContent="space-between">
              <Grid item>
                <Button
                  variant="contained"
                  color="inherit"
                  onClick={() => onSubmit("back")}
                >
                  {t("backButtonLabel")}
                </Button>
              </Grid>
              <Grid item>
                <Button variant="contained" color="primary" type="submit">
                  {t("nextButtonLabel")}
                </Button>
              </Grid>
            </Grid>
          )}
        </Box>
      </form>
    </>
  );
}

export default Account;
