import React, { useState, useEffect, useContext, createContext } from "react";
import { Buffer } from "buffer";
const authContext = createContext();

export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export const useAuth = () => {
  return useContext(authContext);
};

async function getUserInfo(accessToken) {
  try {
    const me = await fetch(process.env.REACT_APP_CMP_ENDPOINT + "/users/_me", {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return await me.json();
  } catch (error) {
    console.error(error);
    throw error;
  }
}

async function getProjects(accessToken, orgId) {
  try {
    const res = await fetch(
      process.env.REACT_APP_CMP_ENDPOINT +
        `/orgs/${orgId}/projects?enabled=true&size=1000`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return await res.json();
  } catch (error) {
    console.error(error);
    throw error;
  }
}

async function signInWithEmailAndPassword(userName, password) {
  const err = new Error();
  const params = new URLSearchParams();
  const cmpSecret = process.env.REACT_APP_CMP_SECRET;
  params.append("grant_type", "password");
  params.append("username", userName);
  params.append("password", password);
  params.append("client_id", "cm-members-form");
  params.append("client_secret", cmpSecret);
  const basicAuth = Buffer.from(`cm-members-form:${cmpSecret}`).toString(
    "base64"
  );
  try {
    const res = await fetch(
      process.env.REACT_APP_CMP_ENDPOINT + "/oauth/token",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Basic ${basicAuth}`,
        },
        body: params,
      }
    );
    if (!res.ok) {
      err.message = "HTTP status code: " + res.status;
      throw err;
    } else {
      const data = await res.json();
      try {
        const res = await fetch(
          process.env.REACT_APP_CMP_ENDPOINT + "/users/_me",
          {
            headers: {
              Authorization: `Bearer ${data.access_token}`,
            },
          }
        );
        const user = await res.json();
        if (user.authorities.includes("ROLE_POWERUSER")) {
          return data;
        } else {
          console.error(userName + " has not POWERUSER authority.");
          err.message = "NOT_POWERUSER";
          throw err;
        }
      } catch (error) {
        throw error;
      }
    }
  } catch (error) {
    throw error;
  }
}

function useProvideAuth() {
  const [accessToken, setAccessToken] = useState();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const [projects, setProjects] = useState([]);
  const [orgId, setOrgId] = useState(0);
  useEffect(() => {
    const f = async () => {
      if (accessToken) {
        if (user === null) {
          await getUserInfo(accessToken)
            .then((res) => {
              setUser({ ...user, info: res });
              setOrgId(res.organizationId);
            })
            .catch((err) => {
              console.error(err);
            });
        }
      }
    };
    f();
  }, [accessToken, user]);
  useEffect(() => {
    const f = async () => {
      if (orgId > 0) {
        await getProjects(accessToken, orgId)
          .then((res) => {
            if (res?._embedded?.projects) {
              setProjects(res._embedded.projects);
            } else {
              setProjects([]);
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }
    };
    f();
  }, [accessToken, orgId]);
  async function login(email, password) {
    await signInWithEmailAndPassword(email, password)
      .then((res) => {
        setAccessToken(res.access_token);
        setIsAuthenticated(true);
        return res;
      })
      .catch((err) => {
        throw err;
      });
  }
  return {
    accessToken,
    isAuthenticated,
    user,
    projects,
    orgId,
    login,
  };
}
