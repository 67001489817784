import { useForm, Controller, useWatch } from "react-hook-form";
import React, { useContext, useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import { UserInputData } from "./Content";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { useAuth } from "auth";
import Autocomplete from "@mui/material/Autocomplete";
import { FormProvider } from "components/hook-form";
import FormHeader from "components/common/FormHeader";
import PaginationBox from "components/common/PaginationBox";
import { useAddBillingSchema } from "schemas/ecam/Billing";

const defaultValues = {
  billingMethod: "existingProject",
  billingOption: "",
  paymentMethod: "",
  projectId: "",
  projectName: "",
  subscriptionPlan: "",
  paymentCurrency: "",
  invoiceLanguage: "",
  invoicePersonLastName: "",
  invoicePersonFirstName: "",
  invoiceMailAddress: "",
  invoiceZipCode: "",
  invoiceAddressCountry: "",
  invoiceAddressState: "",
  invoiceAddressCity: "",
  invoiceAddressStreet: "",
};

const SHARED_PAYER_ECAM_PLANS = ["CDN_DISCOUNT_PREMIUM", "CDN_DISCOUNT"];

function Billing(props) {
  const { accessToken, projects, orgId } = useAuth();
  const { t } = useTranslation();
  const billingSchema = useAddBillingSchema();
  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(billingSchema),
    defaultValues,
  });
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors, isValid },
  } = methods;
  const { currentState, setCurrentState, isChecking } =
    useContext(UserInputData);
  const [readOnlyInvoiceTo, setReadOnlyInvoiceTo] = useState("");
  const [readOnlyInvoiceCc, setReadOnlyInvoiceCc] = useState("");
  const billing = currentState["Billing"];
  useEffect(() => {
    if (billing) {
      for (const k of Object.keys(getValues())) {
        setValue(k, billing[k], { shouldValidate: true });
      }
    }
  }, [billing, getValues, setValue]);

  const watchPaymentCurrency = useWatch({ control, name: "paymentCurrency" });
  useEffect(() => {
    switch (watchPaymentCurrency) {
      case "USD":
        setValue("invoiceLanguage", "en", { shouldValidate: true });
        break;
      case "JPY":
        setValue("invoiceLanguage", "ja", { shouldValidate: true });
        break;
      default:
        break;
    }
  }, [watchPaymentCurrency, setValue]);

  const watchProjectId = useWatch({ control, name: "projectId" });
  useEffect(() => {
    const f = async () => {
      if (watchProjectId) {
        // CMP プロジェクトの請求通知先アドレスを取得して表示する
        try {
          const res = await fetch(
            process.env.REACT_APP_CMP_ENDPOINT +
              `/orgs/${orgId}/projects/${watchProjectId}/addresses`,
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            }
          );
          if (!res.ok) {
            throw new Error("HTTP status code: " + res.status);
          } else {
            const data = await res.json();
            let invoiceTo = "";
            let invoiceCcs = [];
            if ("_embedded" in data) {
              if ("projectAddresses" in data._embedded) {
                for (const elem of data._embedded.projectAddresses) {
                  if (elem.emailType === "INVOICE_TO") {
                    invoiceTo = elem.email;
                  } else if (elem.emailType === "INVOICE_CC") {
                    invoiceCcs.push(elem.email);
                  }
                }
              }
            }
            // テキストフィールドに INVOICE_TO を表示
            if (invoiceTo !== "") {
              setReadOnlyInvoiceTo(invoiceTo);
            } else {
              setReadOnlyInvoiceTo(t("unregisteredLabel"));
            }
            // テキストフィールドに INVOICE_CC を表示
            if (invoiceCcs.length > 0) {
              setReadOnlyInvoiceCc(invoiceCcs.join("\n"));
            } else {
              setReadOnlyInvoiceCc(t("unregisteredLabel"));
            }
          }
        } catch (error) {
          console.error(error);
          throw error;
        }
      }
    };
    f();
  }, [watchProjectId, orgId, accessToken, t]);

  const onSubmit = (action) => {
    switch (action) {
      case "back":
        props.handleBack();
        break;
      case "ok":
        props.handleConfirm();
        break;
      default:
        props.handleNext();
    }
    const data = getValues();
    setCurrentState({ ...currentState, Billing: data });
  };
  return (
    <>
      <FormHeader
        label={t("billingLabel")}
        description={t("billingDescription")}
      />
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <>
          <Controller
            control={control}
            name="projectId"
            render={({ props }) => (
              <Autocomplete
                options={projects.filter(
                  (i) =>
                    SHARED_PAYER_ECAM_PLANS.includes(i.projectType) === true
                )}
                getOptionLabel={(project) => project.name}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.id}>
                      {option.name}
                    </li>
                  );
                }}
                isOptionEqualToValue={(project, value) =>
                  project.id === value.id
                }
                renderInput={(props) => (
                  <TextField
                    {...props}
                    label={t("projectIdLabel")}
                    error={errors.projectId ? true : false}
                    helperText={errors.projectId?.message}
                    fullWidth
                    margin="normal"
                    key={props.id}
                  />
                )}
                onChange={(event, value) => {
                  if (value !== null) {
                    setValue("projectId", value.id, {
                      shouldValidate: true,
                    });
                  }
                }}
              />
            )}
          />
          <TextField
            disabled
            label={t("invoiceToLabel")}
            fullWidth
            margin="normal"
            id="readOnlyInvoiceTo"
            value={readOnlyInvoiceTo}
            InputProps={{
              readOnly: true,
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            disabled
            label={t("invoiceCcLabel")}
            fullWidth
            rows={6}
            multiline
            margin="normal"
            id="readOnlyInvoiceCc"
            value={readOnlyInvoiceCc}
            InputProps={{
              readOnly: true,
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </>
        <PaginationBox
          isChecking={isChecking}
          isValid={isValid}
          onSubmit={onSubmit}
        />
      </FormProvider>
    </>
  );
}

export default Billing;
