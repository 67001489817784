import { Grid } from "@mui/material";
import { useForm, Controller, useWatch } from "react-hook-form";
import React, { useContext, useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import { Button, MenuItem } from "@mui/material";
import Box from "@mui/material/Box";
import { UserInputData } from "./Content";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import CustomTooltip from "components/common/CustomTooltip";

const getBillingSchema = ({ t }) => {
  return Yup.object().shape({
    billingMethod: Yup.string().oneOf(
      ["existingProject", "newProject"],
      t("errorSelectBillingMethod")
    ),
    projectName: Yup.string()
      .required(t("errorRequired"))
      .max(32, t("errorProjectNameMaxLimit"))
      .matches(/^[a-zA-Z0-9!-/:-@¥[-`{-~ ]*$/, t("errorProjectNameFormat")),
    cmpOrganizationCode: Yup.string()
      .required(t("errorRequired"))
      .min(3, t("errorCmpOrganizationCodeMinLimit"))
      .max(32, t("errorCmpOrganizationCodeMaxLimit"))
      .matches(/^[a-zA-Z0-9_]*$/, t("errorMatchCmpOrganizationCodeFormat")),
    paymentCurrency: Yup.string().when("billingMethod", {
      is: (val) => val === "newProject",
      then: Yup.string().oneOf(["USD", "JPY"], t("errorSelectPaymentCurrency")),
      otherwise: Yup.string().notRequired(),
    }),
    invoiceLanguage: Yup.string().when("billingMethod", {
      is: (val) => val === "newProject",
      then: Yup.string().oneOf(["en", "ja"], t("errorSelectInvoiceLanguage")),
      otherwise: Yup.string().notRequired(),
    }),
    invoicePersonFirstName: Yup.string().when("billingMethod", {
      is: (val) => val === "newProject",
      then: Yup.string().required(t("errorRequired")),
      otherwise: Yup.string().notRequired(),
    }),
    invoicePersonLastName: Yup.string().when("billingMethod", {
      is: (val) => val === "newProject",
      then: Yup.string().required(t("errorRequired")),
      otherwise: Yup.string().notRequired(),
    }),
    invoiceMailAddress: Yup.string().when("billingMethod", {
      is: (val) => val === "newProject",
      then: Yup.string().required(t("errorRequired")).email(t("errorEmail")),
      otherwise: Yup.string().notRequired(),
    }),
  });
};

function Billing(props) {
  const { t } = useTranslation();
  const billingSchema = getBillingSchema({ t });
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      billingMethod: "",
      projectName: "",
      cmpOrganizationCode: "",
      paymentCurrency: "",
      invoiceLanguage: "",
      invoicePersonFirstName: "",
      invoicePersonLastName: "",
      invoiceMailAddress: "",
    },
    resolver: yupResolver(billingSchema),
  });
  const { currentState, setCurrentState, isChecking } =
    useContext(UserInputData);
  const [isNeed, setIsNeed] = useState(false);

  const billing = currentState["Billing"];
  useEffect(() => {
    if (billing) {
      for (const k of Object.keys(getValues())) {
        setValue(k, billing[k], { shouldValidate: true });
      }
      switch (billing?.billingMethod) {
        case "newProject":
          setIsNeed(true);
          break;
        default:
          break;
      }
    }
  }, [billing, getValues, setValue]);

  const watchPaymentCurrency = useWatch({ control, name: "paymentCurrency" });
  useEffect(() => {
    switch (watchPaymentCurrency) {
      case "USD":
        setValue("invoiceLanguage", "en", { shouldValidate: true });
        break;
      case "JPY":
        setValue("invoiceLanguage", "ja", { shouldValidate: true });
        break;
      default:
        break;
    }
  }, [watchPaymentCurrency, setValue]);

  const watchBillingMethod = useWatch({ control, name: "billingMethod" });
  useEffect(() => {
    if (watchBillingMethod === "newProject") {
      setIsNeed(true);
    } else if (watchBillingMethod === "existingProject") {
      setValue("invoicePersonLastName", "", { shouldValidate: true });
      setValue("invoicePersonFirstName", "", { shouldValidate: true });
      setValue("invoiceMailAddress", "", { shouldValidate: true });
      setValue("paymentCurrency", "", { shouldValidate: true });
      setValue("invoiceLanguage", "", { shouldValidate: true });
      setIsNeed(false);
    }
  }, [watchBillingMethod, setValue]);

  const onSubmit = (action) => {
    switch (action) {
      case "back":
        props.handleBack();
        break;
      case "ok":
        props.handleConfirm();
        break;
      default:
        props.handleNext();
    }
    const data = getValues();
    setCurrentState({ ...currentState, Billing: data });
  };
  return (
    <>
      <Typography gutterBottom variant="h4" component="h2">
        {t("billingLabel")}
      </Typography>
      <Typography variant="body2" color="textSecondary">
        {t("billingDescription")}
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          name="billingMethod"
          render={({ field }) => (
            <TextField
              {...field}
              label={t("billingMethodLabel")}
              error={errors.billingMethod ? true : false}
              helperText={errors.billingMethod?.message}
              fullWidth
              margin="normal"
              id="select"
              select
            >
              <MenuItem value="existingProject">
                {t("existingProjectLabel")}
              </MenuItem>
              <MenuItem value="newProject">{t("newProjectLabel")}</MenuItem>
            </TextField>
          )}
        />
        <Controller
          control={control}
          name="projectName"
          render={({ field }) => (
            <TextField
              {...field}
              label={t("projectNameLabel")}
              error={errors.projectName ? true : false}
              helperText={errors.projectName?.message}
              fullWidth
              margin="normal"
              placeholder={t("projectNamePlaceholder")}
            />
          )}
        />
        <CustomTooltip title={t("projectNameTooltipTitle")} />
        <Controller
          control={control}
          name="cmpOrganizationCode"
          render={({ field }) => (
            <TextField
              {...field}
              label={t("cmpOrganizationCodeLabel")}
              error={errors.cmpOrganizationCode ? true : false}
              helperText={errors.cmpOrganizationCode?.message}
              fullWidth
              margin="normal"
            />
          )}
        />
        <CustomTooltip title={t("cmpOrganizationCodeTooltipTitle")} />
        {isNeed ? (
          <>
            <Controller
              control={control}
              name="paymentCurrency"
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t("paymentCurrencyLabel")}
                  error={errors.paymentCurrency ? true : false}
                  helperText={errors.paymentCurrency?.message}
                  fullWidth
                  margin="normal"
                  id="select"
                  select
                >
                  <MenuItem value="USD">{t("USDLabel")}</MenuItem>
                  <MenuItem value="JPY">{t("JPYLabel")}</MenuItem>
                </TextField>
              )}
            />
            <Controller
              control={control}
              name="invoiceLanguage"
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t("invoiceLanguageLabel")}
                  error={errors.invoiceLanguage ? true : false}
                  helperText={errors.invoiceLanguage?.message}
                  fullWidth
                  margin="normal"
                  id="select"
                  select
                  disabled={true}
                >
                  <MenuItem value="en">{t("enLabel")}</MenuItem>
                  <MenuItem value="ja">{t("jaLabel")}</MenuItem>
                </TextField>
              )}
            />
            <CustomTooltip title={t("invoiceLanguageTooltipTitle")} />
            <Controller
              control={control}
              name="invoicePersonFirstName"
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t("invoicePersonFirstNameLabel")}
                  error={errors.invoicePersonFirstName ? true : false}
                  helperText={errors.invoicePersonFirstName?.message}
                  fullWidth
                  margin="normal"
                />
              )}
            />
            <Controller
              control={control}
              name="invoicePersonLastName"
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t("invoicePersonLastNameLabel")}
                  error={errors.invoicePersonLastName ? true : false}
                  helperText={errors.invoicePersonLastName?.message}
                  fullWidth
                  margin="normal"
                />
              )}
            />
            <Controller
              control={control}
              name="invoiceMailAddress"
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t("invoiceMailAddressLabel")}
                  error={errors.invoiceMailAddress ? true : false}
                  helperText={errors.invoiceMailAddress?.message}
                  fullWidth
                  margin="normal"
                />
              )}
            />
            <CustomTooltip title={t("invoiceMailAddressTooltipTitle")} />
          </>
        ) : (
          ""
        )}
        <Box mt={4} className="mb60">
          {isChecking ? (
            <Grid container spacing={1} justifyContent="flex-end">
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  type="button"
                  onClick={() => onSubmit("ok")}
                  disabled={!isValid}
                >
                  {t("okButtonLabel")}
                </Button>
              </Grid>
            </Grid>
          ) : (
            <Grid container spacing={1} justifyContent="space-between">
              <Grid item>
                <Button
                  variant="contained"
                  color="inherit"
                  onClick={() => onSubmit("back")}
                >
                  {t("backButtonLabel")}
                </Button>
              </Grid>
              <Grid item>
                <Button variant="contained" color="primary" type="submit">
                  {t("nextButtonLabel")}
                </Button>
              </Grid>
            </Grid>
          )}
        </Box>
      </form>
    </>
  );
}

export default Billing;
