import { Grid } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import React, { useContext, useEffect } from "react";
import TextField from "@mui/material/TextField";
import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import { UserInputData } from "./Content";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";

const getCompanySchema = ({ t, i18n }) => {
  if (i18n.language === "en") {
    return Yup.object().shape({
      companyName: Yup.string().required(t("errorRequired")),
      siteUrl: Yup.string()
        .required(t("errorRequired"))
        .url(t("errorMatchSiteUrl")),
      zipCode: Yup.string().required(t("errorRequired")),
      country: Yup.string().required(t("errorRequired")),
      prefecture: Yup.string().required(t("errorRequired")),
      companyAddress: Yup.string().required(t("errorRequired")),
      phoneNumber: Yup.string()
        .required(t("errorRequired"))
        .matches(/^\+?[1-9]\d{1,14}$/, t("errorMatchPhoneNumber")),
    });
  } else {
    return Yup.object().shape({
      companyName: Yup.string().required(t("errorRequired")),
      siteUrl: Yup.string()
        .required(t("errorRequired"))
        .url(t("errorMatchSiteUrl")),
      zipCode: Yup.string()
        .required(t("errorRequired"))
        .matches(/^[0-9]{7,7}$/, t("errorZipCodeFormat")),
      country: Yup.string().required(t("errorRequired")),
      prefecture: Yup.string().required(t("errorRequired")),
      companyAddress: Yup.string().required(t("errorRequired")),
      phoneNumber: Yup.string()
        .required(t("errorRequired"))
        .matches(/^[0-9]{10,11}$/, t("errorMatchPhoneNumber")),
    });
  }
};

function Company(props) {
  const { t, i18n } = useTranslation();
  const companySchema = getCompanySchema({ t, i18n });
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      companyName: "",
      siteUrl: "",
      zipCode: "",
      country: "",
      prefecture: "",
      companyAddress: "",
      addressLine: "",
      phoneNumber: "",
    },
    resolver: yupResolver(companySchema),
  });
  const { currentState, setCurrentState, isChecking } =
    useContext(UserInputData);

  const company = currentState["Company"];
  useEffect(() => {
    if (company) {
      for (const k of Object.keys(getValues())) {
        setValue(k, company[k], { shouldValidate: true });
      }
    }
  }, [company, getValues, setValue]);

  const onSubmit = (action) => {
    if (action === "ok") {
      props.handleConfirm();
    } else {
      props.handleNext();
    }
    const data = getValues();
    setCurrentState({ ...currentState, Company: data });
  };
  return (
    <>
      <Typography gutterBottom variant="h4" component="h2">
        {t("companyLabel")}
      </Typography>
      <Typography variant="body2" color="textSecondary">
        {t("companyDescription")}
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          name="companyName"
          render={({ field }) => (
            <TextField
              {...field}
              label={t("companyNameLabel")}
              error={errors.companyName ? true : false}
              helperText={errors.companyName?.message}
              fullWidth
              margin="normal"
              placeholder={t("companyNamePlaceholder")}
            />
          )}
        />
        <Controller
          control={control}
          name="siteUrl"
          render={({ field }) => (
            <TextField
              {...field}
              label={t("siteUrlLabel")}
              type="url"
              error={errors.siteUrl ? true : false}
              helperText={errors.siteUrl?.message}
              fullWidth
              margin="normal"
              placeholder={t("siteUrlPlaceholder")}
            />
          )}
        />
        <Controller
          control={control}
          name="zipCode"
          render={({ field }) => (
            <TextField
              {...field}
              label={t("zipCodeLabel")}
              error={errors.zipCode ? true : false}
              helperText={errors.zipCode?.message}
              fullWidth
              margin="normal"
            />
          )}
        />
        <Controller
          control={control}
          name="country"
          render={({ field }) => (
            <TextField
              {...field}
              label={t("countryLabel")}
              error={errors.country ? true : false}
              helperText={errors.country?.message}
              fullWidth
              margin="normal"
            />
          )}
        />
        <Controller
          control={control}
          name="prefecture"
          render={({ field }) => (
            <TextField
              {...field}
              label={t("prefectureLabel")}
              error={errors.prefecture ? true : false}
              helperText={errors.prefecture?.message}
              fullWidth
              margin="normal"
            />
          )}
        />
        <Controller
          control={control}
          name="companyAddress"
          render={({ field }) => (
            <TextField
              {...field}
              label={t("companyAddressLabel")}
              error={errors.companyAddress ? true : false}
              helperText={errors.companyAddress?.message}
              fullWidth
              margin="normal"
            />
          )}
        />
        <Controller
          control={control}
          name="addressLine"
          render={({ field }) => (
            <TextField
              {...field}
              label={t("addressLineLabel")}
              fullWidth
              margin="normal"
            />
          )}
        />
        <Controller
          control={control}
          name="phoneNumber"
          render={({ field }) => (
            <TextField
              {...field}
              label={t("phoneNumberLabel")}
              type="tel"
              error={errors.phoneNumber ? true : false}
              helperText={errors.phoneNumber?.message}
              fullWidth
              margin="normal"
              placeholder={t("phoneNumberPlaceholder")}
            />
          )}
        />
        <Box mt={4} className="mb60">
          <Grid container spacing={1} justifyContent="flex-end">
            <Grid item>
              {isChecking ? (
                <Button
                  variant="contained"
                  color="primary"
                  type="button"
                  onClick={() => onSubmit("ok")}
                  disabled={!isValid}
                >
                  {t("okButtonLabel")}
                </Button>
              ) : (
                <Button variant="contained" color="primary" type="submit">
                  {t("nextButtonLabel")}
                </Button>
              )}
            </Grid>
          </Grid>
        </Box>
      </form>
    </>
  );
}

export default Company;
