import PropTypes from "prop-types";
import { useFormContext, Controller } from "react-hook-form";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
} from "@mui/material";

RHFCheckbox.propTypes = {
  name: PropTypes.string.isRequired,
};

export default function RHFCheckbox({ name, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => {
        return (
          <>
            <FormControl error={fieldState.invalid}>
              <FormControlLabel
                key={field.key}
                control={<Checkbox {...field} checked={field.value} />}
                label={field.label}
                {...other}
              />
              <FormHelperText>{fieldState.error?.message}</FormHelperText>
            </FormControl>
          </>
        );
      }}
    />
  );
}
