import {
  Grid,
  TextField,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Box,
  Radio,
  RadioGroup,
  Typography,
  Autocomplete,
  FormLabel,
} from "@mui/material";
import { useForm, Controller, useWatch } from "react-hook-form";
import React, { useContext, useEffect, useState } from "react";
import { UserInputData } from "./Content";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useAuth } from "auth";
import CustomTooltip from "components/common/CustomTooltip";

const getContractSchema = ({ t }) => {
  return Yup.object().shape({
    cancelAccounts: Yup.array().min(1, t("errorSelectCancelAccounts")),
    cancellationReason: Yup.string()
      .required(t("errorRequired"))
      .oneOf(
        [
          "dissatisfiedService",
          "switchAnotherService",
          "companyPolicy",
          "accountManageInHouse",
          "other",
        ],
        t("errorSelectCancellationReason")
      ),
    detailReason: Yup.string().when("cancellationReason", {
      is: (val) => val === "other",
      then: Yup.string().required(t("errorRequired")),
      otherwise: Yup.string().notRequired(),
    }),
  });
};

function Contract(props) {
  const { accessToken, orgId } = useAuth();
  const { t } = useTranslation();
  const contractSchema = getContractSchema({ t });
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      cancelAccounts: [],
      cancellationReason: "",
      detailReason: "",
    },
    resolver: yupResolver(contractSchema),
  });
  const { currentState, setCurrentState, isChecking } =
    useContext(UserInputData);
  const [awsAccounts, setAwsAccounts] = useState([]);

  useEffect(() => {
    const getAccounts = async (accessToken) => {
      try {
        const res = await fetch(
          process.env.REACT_APP_CMP_ENDPOINT +
            `/aws-accounts?enabled=true&size=1000&organizationId=${orgId}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        return await res.json();
      } catch (error) {
        console.error(error);
        throw error;
      }
    };
    getAccounts(accessToken)
      .then((res) => {
        if (res.hasOwnProperty("_embedded")) {
          setAwsAccounts(res?._embedded?.awsAccounts);
        } else {
          // AWS アカウントが存在しない場合
          // 解約対象の AWS アカウントフィールドをリセット
          setAwsAccounts([]);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, [accessToken, orgId]);
  const watchCancellationReason = useWatch({
    control,
    name: "cancellationReason",
  });
  useEffect(() => {
    if (watchCancellationReason !== "other") {
      setValue("detailReason", "", { shouldValidate: true });
    }
  }, [watchCancellationReason, setValue]);

  const require = currentState["Require"];
  useEffect(() => {
    if (require) {
      for (const k of Object.keys(getValues())) {
        if (k !== "cancelAccounts") {
          setValue(k, require[k], { shouldValidate: true });
        }
      }
    }
  }, [require, getValues, setValue]);

  const onSubmit = (action) => {
    switch (action) {
      case "back":
        props.handleBack();
        break;
      case "ok":
        props.handleConfirm();
        break;
      default:
        props.handleNext();
    }
    const data = getValues();
    // AWS アカウントID だけ抽出する
    data.cancelAccounts = data?.cancelAccounts?.map((obj) => obj.accountId);
    setCurrentState({ ...currentState, Require: data });
  };
  return (
    <>
      <Typography gutterBottom variant="h4" component="h2">
        {t("requireLabel")}
      </Typography>
      <Typography variant="body2" color="textSecondary">
        {t("requireDescription")}
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          name="cancelAccounts"
          render={({ props }) => (
            <Autocomplete
              multiple
              options={awsAccounts}
              getOptionLabel={(i) =>
                i["accountId"] + "（" + i["accountName"] + "）"
              }
              filterSelectedOptions
              renderInput={(props) => (
                <TextField
                  {...props}
                  label={t("cancelAccountsLabel")}
                  error={errors.cancelAccounts ? true : false}
                  helperText={errors.cancelAccounts?.message}
                  fullWidth
                  margin="normal"
                  key={props.id}
                />
              )}
              onChange={(event, value) => {
                setValue("cancelAccounts", value, { shouldValidate: true });
              }}
            />
          )}
        />
        <CustomTooltip title={t("multiAccountsTooltipTitle")} />
        <Box>
          <Controller
            control={control}
            name="cancellationReason"
            render={({ field: { value, onChange } }) => (
              <FormControl>
                <FormLabel>{t("cancelFormLabel")}</FormLabel>
                <RadioGroup
                  onChange={onChange}
                  value={value === undefined ? "" : value}
                >
                  <FormControlLabel
                    value="dissatisfiedService"
                    control={<Radio color="primary" />}
                    label={t("dissatisfiedServiceLabel")}
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    value="switchAnotherService"
                    control={<Radio color="primary" />}
                    label={t("switchAnotherServiceLabel")}
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    value="companyPolicy"
                    control={<Radio color="primary" />}
                    label={t("companyPolicyLabel")}
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    value="accountManageInHouse"
                    control={<Radio color="primary" />}
                    label={t("accountManageInHouseLabel")}
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    value="other"
                    control={<Radio color="primary" />}
                    label={t("otherLabel")}
                    labelPlacement="end"
                  />
                </RadioGroup>
                <FormHelperText error>
                  {errors.cancellationReason?.message}
                </FormHelperText>
              </FormControl>
            )}
          />
          <Controller
            control={control}
            name="detailReason"
            render={({ field }) => (
              <TextField
                {...field}
                label={t("detailReasonLabel")}
                error={errors.detailReason ? true : false}
                helperText={errors.detailReason?.message}
                fullWidth
                margin="normal"
                rows={4}
                multiline
                variant="outlined"
                placeholder={t("detailReasonPlaceholder")}
              />
            )}
          />
        </Box>
        <Box mt={4} className="mb60">
          {isChecking ? (
            <Grid container spacing={1} justifyContent="flex-end">
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  type="button"
                  onClick={() => onSubmit("ok")}
                  disabled={!isValid}
                >
                  {t("okButtonLabel")}
                </Button>
              </Grid>
            </Grid>
          ) : (
            <Grid container spacing={1} justifyContent="flex-end">
              <Grid item>
                <Button variant="contained" color="primary" type="submit">
                  {t("nextButtonLabel")}
                </Button>
              </Grid>
            </Grid>
          )}
        </Box>
      </form>
    </>
  );
}

export default Contract;
