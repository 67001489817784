import * as Yup from "yup";
import { useTranslation } from "react-i18next";

export function usePlanSchema() {
  const { t } = useTranslation();
  return Yup.object().shape({
    projectId: Yup.string().required(t("errorRequired")),
    subscriptionPlan: Yup.string()
      .required(t("errorRequired"))
      .oneOf(
        [
          "V2_MEMBERS",
          "V2_PREMIUM",
          "START",
          "MEMBERS",
          "ORG_CDN_DISCOUNT_PREMIUM",
          "ORG_PRIVATE_DISCOUNT_PREMIUM",
          "ORGANIZATION_PREMIUM",
          "CDN_DISCOUNT_PREMIUM",
          "ORG_FLATRATE_DISCOUNT_PREMIUM",
          "ORG_FLATRATE_DISCOUNT_4_PREMIUM",
          "ORG_FLATRATE_DISCOUNT_5_PREMIUM",
          "FLATRATE_DISCOUNT_6_PREMIUM",
          "FLATRATE_DISCOUNT_7_PREMIUM",
          "FLATRATE_DISCOUNT_8_PREMIUM",
          "FLATRATE_DISCOUNT_9_PREMIUM",
          "FLATRATE_DISCOUNT_10_PREMIUM",
          "FLATRATE_DISCOUNT_11_PREMIUM",
          "FLATRATE_DISCOUNT_12_PREMIUM",
          "ORG_PRIVATE_DISCOUNT_1_PREMIUM",
          "ORG_PRIVATE_DISCOUNT_2_PREMIUM",
          "ORG_PRIVATE_DISCOUNT_3_PREMIUM",
          "FLATRATE_DISCOUNT_2_PREMIUM",
          "ORG_FLATRATE_DISCOUNT_2_PREMIUM",
        ],
        t("errorSelectSubscriptionPlan")
      ),
  });
}
