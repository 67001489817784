import Typography from "@mui/material/Typography";
import Toolbar from "@mui/material/Toolbar";
import { styled } from "@mui/material/styles";

const WrapFooter = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  position: "absolute",
  bottom: "0",
  width: "100%",
  background: "black",
  color: "white",
  fontSize: "12px",
});

const InnerFooter = styled(Typography)({
  variant: "body1",
  color: "inherit",
  fontSize: "12px",
});

function Footer() {
  return (
    <WrapFooter>
      <Toolbar>
        <InnerFooter>@ Classmethod, Inc.</InnerFooter>
      </Toolbar>
    </WrapFooter>
  );
}

export default Footer;
