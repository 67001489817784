import {
  Card,
  Typography,
  CardActionArea,
  ButtonBase,
  CardContent,
} from "@mui/material";
import OpenInNewOutlined from "@mui/icons-material/OpenInNewOutlined";

function SubCardLink({ href, title }) {
  return (
    <Card>
      <CardActionArea>
        <ButtonBase
          sx={{
            display: "block",
            textAlign: "initial",
            color: "#D3B735",
          }}
          href={href}
          target="_blank"
        >
          <CardContent>
            <Typography variant="h6">
              {title}
              <OpenInNewOutlined fontSize="small" />
            </Typography>
          </CardContent>
        </ButtonBase>
      </CardActionArea>
    </Card>
  );
}
export default SubCardLink;
