import { Grid, Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { useAuth } from "auth";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
    color: "#111111",
  },
}));

function ConfirmTable({ currentState, editPage, pageIndex }) {
  const { projects } = useAuth();
  const { t } = useTranslation();
  const tableContents = [];
  for (const k of [
    "Wafcharm",
    "Project",
    "Company",
    "Applicant",
    "Account",
    "Billing",
    "Plan",
    "Portal",
    "Require",
    "Transferee",
    "ControlTower",
    "Optional",
    "Others",
  ]) {
    const tableData = [];
    if (currentState.hasOwnProperty(k)) {
      for (var v in currentState[k]) {
        var value = "";
        switch (true) {
          case currentState[k][v] === true:
            // Boolean は同意系のチェックボックスで利用
            value = t("yesLabel");
            break;
          case currentState[k][v] === "":
          case currentState[k][v] === false:
            if (k === "Others" || k === "Optional") {
              // Others,Optional はすべて任意入力のため、テーブルセルが空になる場合あり
              value = t("noInputLabel");
              break;
            } else {
              continue;
            }
          // プロジェクトID は、プロジェクト名に変換する
          case v === "projectId":
          case v === "destinationProjectId":
          case v === "targetProjectId":
            for (var i in projects) {
              if (projects[i]["id"] === currentState[k][v]) {
                value = projects[i]["name"];
                break;
              } else {
                value = currentState[k][v];
              }
            }
            break;
          // for Plan Form
          case k === "Plan" && v === "subscriptionPlan":
            value = t(currentState[k][v] + "Label");
            v = "subscriptionChangedPlan";
            break;
          case v === "cancelAccounts":
          case v === "awsAccounts":
          case v === "targetAccounts":
          case v === "changeAccounts":
            value = currentState[k][v].join(", ");
            break;
          default:
            // i18n 辞書データに存在するキーは、翻訳データを取得する
            value = t(currentState[k][v] + "Label", currentState[k][v]);
        }
        tableData.push({
          key: v,
          name: t(v + "Label"),
          value: value,
        });
      }
      tableContents.push(
        <>
          <Box mb={6}>
            <Grid
              container
              justifyContent="space-between"
              className="Confirm-table-title"
            >
              <Typography variant="h5">
                {t(k.toLowerCase() + "Label")}
              </Typography>
              <Button
                id={k + "-button"}
                variant="outlined"
                color="inherit"
                onClick={() => editPage(pageIndex[k])}
                className="Fix-btn"
              >
                {t("modifyButtonLabel")}
              </Button>
            </Grid>
            <TableContainer>
              <Table aria-label="Customer Input Data">
                <TableBody>
                  {tableData.map(function (elem) {
                    return (
                      <TableRow key={elem.key.toString()}>
                        <StyledTableCell component="th" scope="row">
                          {elem.name}
                        </StyledTableCell>
                        <StyledTableCell>{elem.value}</StyledTableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </>
      );
    }
  }
  return tableContents;
}

export default ConfirmTable;
