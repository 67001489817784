import * as Yup from "yup";
import { useTranslation } from "react-i18next";

export function useAccountSchema() {
  const { t } = useTranslation();
  return Yup.object().shape({
    subscriptionType: Yup.string()
      .required(t("errorRequired"))
      .oneOf(
        ["newAccount", "transferAccount", "receiveAccount"],
        t("errorSelectSubscriptionType")
      ),
    accountId: Yup.string().when("subscriptionType", {
      is: (val) => val === "transferAccount" || val === "receiveAccount",
      then: Yup.string()
        .required(t("errorRequired"))
        .min(12, t("errorAwsAccountFormat"))
        .max(12, t("errorAwsAccountFormat"))
        .matches(/^[0-9]{12}$/, t("errorAwsAccountFormat")),
      otherwise: Yup.string().notRequired(),
    }),
    masterAccountId: Yup.string()
      .required(t("errorRequired"))
      .min(12, t("errorAwsAccountFormat"))
      .max(12, t("errorAwsAccountFormat"))
      .matches(/^[0-9]{12}$/, t("errorAwsAccountFormat")),
    applicationNumber: Yup.string().when("subscriptionType", {
      is: (val) => val === "receiveAccount",
      then: Yup.string().required(t("errorRequired")),
      otherwise: Yup.string().notRequired(),
    }),
    createUser: Yup.string().when("subscriptionType", {
      is: (val) => val === "receiveAccount",
      then: Yup.string()
        .required(t("errorRequired"))
        .oneOf(["notRequired", "required"], t("errorSelectSubscriptionType")),
      otherwise: Yup.string().notRequired(),
    }),
    accountName: Yup.string()
      .trim()
      .required(t("errorRequired"))
      .min(2, t("errorAccountNameMinLimit"))
      .max(50, t("errorAccountNameMaxLimit"))
      .matches(
        /^[a-zA-Z0-9!-/:-@¥[-`{-~ ]*$/,
        t("errorMatchAccountNameFormat")
      ),
    techAddress: Yup.string()
      .required(t("errorRequired"))
      .email(t("errorEmail")),
    ccTechAddress: Yup.string().notRequired().email(t("errorEmail")),
    securityAddress: Yup.string()
      .required(t("errorRequired"))
      .email(t("errorEmail")),
    ccSecurityAddress: Yup.string().notRequired().email(t("errorEmail")),
    billingAddress: Yup.string()
      .required(t("errorRequired"))
      .email(t("errorEmail")),
    ccBillingAddress: Yup.string().notRequired().email(t("errorEmail")),
    selectEmergencyContact: Yup.string().oneOf(
      ["registerEmergencyContact", "notRegisterEmergencyContact"],
      t("errorSelectRegisterEmergencyContact")
    ),
    firstEmergencyContactPhoneNumber: Yup.string().when(
      "selectEmergencyContact",
      {
        is: (val) => val === "registerEmergencyContact",
        then: Yup.string()
          .required(t("errorRequired"))
          .matches(
            /^[0-9]{10,11}$/,
            t("errorMatchEmergencyContactPhoneNumberFormat")
          ),
        otherwise: Yup.string().notRequired(),
      }
    ),
    firstEmergencyContactPersonName: Yup.string().when(
      "selectEmergencyContact",
      {
        is: (val) => val === "registerEmergencyContact",
        then: Yup.string()
          .required(t("errorRequired"))
          .matches(
            /^[\u30A0-\u30FF]+$/,
            t("errorMatchEmergencyContactPersonNameFormat")
          ),
        otherwise: Yup.string().notRequired(),
      }
    ),
    secondEmergencyContactPhoneNumber: Yup.string().matches(/^[0-9]{10,11}$/, {
      message: t("errorMatchEmergencyContactPhoneNumberFormat"),
      excludeEmptyString: true,
    }),
    secondEmergencyContactPersonName: Yup.string().when(
      "secondEmergencyContactPhoneNumber",
      {
        is: (val) => val.length > 0,
        then: Yup.string()
          .required(t("errorRequired"))
          .matches(
            /^[\u30A0-\u30FF]+$/,
            t("errorMatchEmergencyContactPersonNameFormat")
          ),
        otherwise: Yup.string().notRequired(),
      }
    ),
    thirdEmergencyContactPhoneNumber: Yup.string().matches(/^[0-9]{10,11}$/, {
      message: t("errorMatchEmergencyContactPhoneNumberFormat"),
      excludeEmptyString: true,
    }),
    thirdEmergencyContactPersonName: Yup.string().when(
      "thirdEmergencyContactPhoneNumber",
      {
        is: (val) => val.length > 0,
        then: Yup.string()
          .required(t("errorRequired"))
          .matches(
            /^[\u30A0-\u30FF]+$/,
            t("errorMatchEmergencyContactPersonNameFormat")
          ),
        otherwise: Yup.string().notRequired(),
      }
    ),
    enableCloudTrail: Yup.boolean().oneOf([true], t("errorAgreement")),
    enableAwsConfig: Yup.boolean().oneOf([true], t("errorAgreement")),
    selectControlTower: Yup.string().when("subscriptionType", {
      is: (val) => val === "newAccount",
      then: Yup.string().oneOf(
        ["enableControlTower", "disableControlTower"],
        t("errorRequired")
      ),
      otherwise: Yup.string().notRequired(),
    }),
    organizationalUnits: Yup.string().when("selectControlTower", {
      is: (val) => val === "enableControlTower",
      then: Yup.string().required(t("errorRequired")),
      otherwise: Yup.string().notRequired(),
    }),
    ssoUserLastName: Yup.string().when("selectControlTower", {
      is: (val) => val === "enableControlTower",
      then: Yup.string().required(t("errorRequired")),
      otherwise: Yup.string().notRequired(),
    }),
    ssoUserFirstName: Yup.string().when("selectControlTower", {
      is: (val) => val === "enableControlTower",
      then: Yup.string().required(t("errorRequired")),
      otherwise: Yup.string().notRequired(),
    }),
    ssoUserAddress: Yup.string().when("selectControlTower", {
      is: (val) => val === "enableControlTower",
      then: Yup.string()
        .required(t("errorRequired"))
        .min(6, t("errorSsoUserAddressMinFormat"))
        .max(64, t("errorSsoUserAddressMaxFormat"))
        .email(t("errorEmail")),
      otherwise: Yup.string().notRequired(),
    }),
  });
}
