import { useState, useRef } from "react";
import {
  Box,
  List,
  Badge,
  Avatar,
  Divider,
  Typography,
  IconButton,
  ListItemText,
  ListItemAvatar,
  ListItemButton,
} from "@mui/material";
import { Notifications, AccessTime } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { fToNow } from "utils/formatTime";
import Scrollbar from "components/common/Scrollbar";
import MenuPopover from "components/common/MenuPopover";

const NOTIFICATIONS = [
  {
    id: 1,
    title: "新規メンバーズプロジェクトの発行停止について",
    description:
      "新規メンバーズプロジェクトの発行を停止いたしました。既存のメンバーズプロジェクトとは異なるご契約 (プラン) や、既存の請求書と分けたい場合は、リニューアルされたポータルサイトからの申請が必要です。",
    createdAt: "2024/05/27 9:00:00",
  },
];

export default function NotificationsPopover() {
  const anchorRef = useRef(null);
  const { t } = useTranslation();
  const [notifications] = useState(NOTIFICATIONS);
  const totalUnRead = notifications.length;
  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  return (
    <>
      <IconButton
        ref={anchorRef}
        color={open ? "primary" : "default"}
        onClick={handleOpen}
        sx={{ width: 40, height: 40 }}
      >
        <Badge badgeContent={totalUnRead} color="error">
          <Notifications width={20} height={20} sx={{ color: "white" }} />
        </Badge>
      </IconButton>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{ width: 360, p: 0, mt: 1.5, ml: 0.75 }}
      >
        <Box sx={{ display: "flex", alignItems: "center", py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">
              {t("notificationsLabel")}
            </Typography>
          </Box>
        </Box>

        <Divider sx={{ borderStyle: "dashed" }} />

        <Scrollbar sx={{ height: { xs: 340, sm: "auto" } }}>
          <List disablePadding>
            {notifications.map((notification) => (
              <NotificationItem
                key={notification.id}
                notification={notification}
              />
            ))}
          </List>
        </Scrollbar>
      </MenuPopover>
    </>
  );
}

function NotificationItem({ notification }) {
  return (
    <ListItemButton
      sx={{
        py: 1.5,
        px: 2.5,
        mt: "1px",
      }}
    >
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: "transparent" }}>
          <img alt="classmethod" src="/icon.png" width="40" height="40" />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={
          <>
            <Typography variant="subtitle2">{notification.title}</Typography>
            <Typography
              component="span"
              variant="body2"
              sx={{ color: "text.secondary" }}
            >
              {notification.description}
            </Typography>
          </>
        }
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: "flex",
              alignItems: "center",
              color: "text.disabled",
            }}
          >
            <AccessTime sx={{ mr: 0.5, width: 16, height: 16 }} />
            {fToNow(notification.createdAt)}
          </Typography>
        }
      />
    </ListItemButton>
  );
}
