import * as Yup from "yup";
import { useTranslation } from "react-i18next";

export function useProjectSchema() {
  const { t } = useTranslation();
  return Yup.object().shape({
    requestType: Yup.string()
      .required(t("errorRequired"))
      .oneOf(["projectIntegration"], t("errorSelectRequestType")),
    targetAccounts: Yup.array().min(1, t("errorSelectCancelAccounts")),
    destinationProjectId: Yup.string().when("requestType", {
      is: (val) => val === "projectIntegration",
      then: Yup.string().required(t("errorRequired")),
      otherwise: Yup.string().notRequired(),
    }),
    destinationProjectName: Yup.string().when("requestType", {
      is: (val) => val === "projectSplit",
      then: Yup.string()
        .required(t("errorRequired"))
        .max(32, t("errorProjectNameMaxLimit"))
        .matches(/^[a-zA-Z0-9!-/:-@¥[-`{-~ ]*$/, t("errorProjectNameFormat")),
      otherwise: Yup.string().notRequired(),
    }),
    invoicePersonFirstName: Yup.string().when("requestType", {
      is: (val) => val === "projectSplit",
      then: Yup.string().required(t("errorRequired")),
      otherwise: Yup.string().notRequired(),
    }),
    invoicePersonLastName: Yup.string().when("requestType", {
      is: (val) => val === "projectSplit",
      then: Yup.string().required(t("errorRequired")),
      otherwise: Yup.string().notRequired(),
    }),
    invoiceMailAddress: Yup.string().when("requestType", {
      is: (val) => val === "projectSplit",
      then: Yup.string().required(t("errorRequired")).email(t("errorEmail")),
      otherwise: Yup.string().notRequired(),
    }),
  });
}
