import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Company from "./Company";
import Applicant from "./Applicant";
import Billing from "./Billing";
import Account from "./Account";
import Others from "./Others";
import Confirm from "./Confirm";
import Agreement from "./Agreement";
import Thanks from "components/common/Thanks";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

function getSteps({ t }) {
  return [
    t("agreementLabel"),
    t("companyLabel"),
    t("applicantLabel"),
    t("accountLabel"),
    t("billingLabel"),
    t("othersLabel"),
    t("confirmLabel"),
  ];
}

export const UserInputData = React.createContext();

function getStepContent(
  stepIndex,
  handleNext,
  handleBack,
  modifyContent,
  handleConfirm
) {
  switch (stepIndex) {
    case 0:
      return <Agreement handleNext={handleNext} />;
    case 1:
      return <Company handleNext={handleNext} handleConfirm={handleConfirm} />;
    case 2:
      return (
        <Applicant
          handleNext={handleNext}
          handleBack={handleBack}
          handleConfirm={handleConfirm}
        />
      );
    case 3:
      return (
        <Account
          handleNext={handleNext}
          handleBack={handleBack}
          handleConfirm={handleConfirm}
        />
      );
    case 4:
      return (
        <Billing
          handleNext={handleNext}
          handleBack={handleBack}
          handleConfirm={handleConfirm}
        />
      );
    case 5:
      return (
        <Others
          handleNext={handleNext}
          handleBack={handleBack}
          handleConfirm={handleConfirm}
        />
      );
    case 6:
      return <Confirm handleNext={handleNext} modifyContent={modifyContent} />;
    default:
      return "An unexpected error has occurred. Please reload the page";
  }
}

function Content() {
  const { t, i18n } = useTranslation();
  const { lang } = useParams();
  const [currentState, setCurrentState] = useState({});
  const [isChecking, setIsChecking] = useState(false);
  const value = {
    currentState,
    setCurrentState,
    isChecking,
    setIsChecking,
  };
  const [activeStep, setActiveStep] = useState(0);
  const [isRequest, setIsRequest] = useState(false);

  // ページのインデックスが更新されるタイミングで、画面表示位置をリセット
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [activeStep]);

  const reqId = currentState["requestId"];
  useEffect(() => {
    if (reqId) {
      setIsRequest(true);
    }
  }, [reqId]);
  useEffect(() => {
    if (lang === "ja") {
      i18n.changeLanguage("ja");
    } else {
      i18n.changeLanguage("en");
    }
  }, [lang, i18n]);
  const steps = getSteps({ t });
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const modifyContent = (pageIndex) => {
    setActiveStep(pageIndex);
  };
  const handleConfirm = () => {
    setActiveStep(6);
  };
  const handleReset = () => {
    setIsChecking(false);
    setActiveStep(0);
  };
  return (
    <Grid container spacing={1}>
      <Grid item sm md lg xl />
      <Grid item xs={12} sm={10} md={10} lg={8} xl={8} sx={{ mt: "30px" }}>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel></StepLabel>
            </Step>
          ))}
        </Stepper>
        <Grid container justifyContent="center">
          <Grid
            item
            xs={11}
            sm={10}
            md={10}
            lg={10}
            xl={10}
            sx={{ mt: "30px" }}
          >
            {activeStep === steps.length ? (
              isRequest ? (
                <Thanks currentState={currentState} handleReset={handleReset} />
              ) : (
                ""
              )
            ) : (
              <UserInputData.Provider value={value}>
                {getStepContent(
                  activeStep,
                  handleNext,
                  handleBack,
                  modifyContent,
                  handleConfirm
                )}
              </UserInputData.Provider>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item sm md lg xl />
    </Grid>
  );
}

export default Content;
