import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  Box,
  CssBaseline,
  TextField,
  Button,
  Avatar,
  Typography,
  Container,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { TailSpin } from "react-loader-spinner";
import { useNavigate, useLocation } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { useAuth } from "auth";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

const getLoginSchema = ({ t }) => {
  return Yup.object().shape({
    userName: Yup.string()
      .required(t("errorRequired"))
      .min(7, t("errorMatchCmpUserNameMinLimit"))
      .max(128, t("errorMatchCmpUserNameMaxLimit"))
      .matches(
        /^[A-Za-z0-9]{1}[A-Za-z0-9.]{1,93}[A-Za-z0-9]{1}@{1}[a-zA-Z0-9_]{3,32}$/,
        t("errorMatchCmpUserNameFormat")
      ),
    password: Yup.string().required(t("errorRequired")),
  });
};

const Login = (props) => {
  const auth = useAuth();
  const { t } = useTranslation();
  const loginSchema = getLoginSchema({ t });
  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      userName: "",
      password: "",
    },
    resolver: yupResolver(loginSchema),
  });
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { from } = location.state || { from: { pathname: "/" } };
  const notifyError = (e) => {
    toast.error(String(e));
  };
  const [isLoading, setIsLoading] = useState(false);
  const handleLogin = async (event) => {
    setIsLoading(true);
    try {
      const form = getValues();
      await auth.login(form.userName, form.password);
      navigate(from, { replace: true });
    } catch (e) {
      setIsLoading(false);
      switch (true) {
        case e.message === "NOT_POWERUSER":
          notifyError(t("errorAuthorityUser"));
          break;
        default:
          notifyError(String(e));
      }
    }
  };
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Toaster position="top-right" duration="10000" />
      {isLoading ? (
        <Box
          sx={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <TailSpin color="#00BFFF" height={160} width={160} />
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            flexWrap: "nowrap",
          }}
        >
          <Avatar src="/logo192.png" className="Singin-avatar"></Avatar>
          <Typography component="h1" variant="h5">
            Members Portal User Login
          </Typography>
          <Box
            sx={{
              fontSize: "12px",
              marginTop: "1rem",
            }}
          >
            <Typography variant="caption">{t("loginNoteLabel")}</Typography>
          </Box>
          <Box
            component="form"
            onSubmit={handleSubmit(handleLogin)}
            noValidate
            sx={{ mt: 1 }}
          >
            <Controller
              control={control}
              name="userName"
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t("userNameLabel")}
                  error={errors.userName ? true : false}
                  helperText={errors.userName?.message}
                  fullWidth
                  margin="normal"
                  id="user"
                  placeholder="manager@classmethod"
                  autoComplete="username"
                />
              )}
            />
            <Controller
              control={control}
              name="password"
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t("passwordLabel")}
                  error={errors.password ? true : false}
                  helperText={errors.password?.message}
                  fullWidth
                  margin="normal"
                  id="password"
                  type={showPassword ? "text" : "password"}
                  autoComplete="current-password"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword(!showPassword)}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
            <Button
              type="submit"
              variant="contained"
              className="Submit-btn"
              color="primary"
            >
              {t("loginButtonLabel")}
            </Button>
          </Box>
        </Box>
      )}
    </Container>
  );
};

export default Login;
