import * as Yup from "yup";
import { useTranslation } from "react-i18next";

export function useAccountSchema() {
  const { t } = useTranslation();
  return Yup.object().shape({
    subscriptionType: Yup.string()
      .required(t("errorRequired"))
      .oneOf(
        ["newAccount", "transferAccount"],
        t("errorSelectSubscriptionType")
      ),
    accountId: Yup.string().when("subscriptionType", {
      is: (val) => val === "transferAccount",
      then: Yup.string()
        .required(t("errorRequired"))
        .min(12, t("errorAwsAccountFormat"))
        .max(12, t("errorAwsAccountFormat"))
        .matches(/^[0-9]{12}$/, t("errorAwsAccountFormat")),
      otherwise: Yup.string().notRequired(),
    }),
    techAddress: Yup.string()
      .required(t("errorRequired"))
      .email(t("errorEmail")),
    ccTechAddress: Yup.string().notRequired().email(t("errorEmail")),
    securityAddress: Yup.string()
      .required(t("errorRequired"))
      .email(t("errorEmail")),
    ccSecurityAddress: Yup.string().notRequired().email(t("errorEmail")),
    billingAddress: Yup.string()
      .required(t("errorRequired"))
      .email(t("errorEmail")),
    ccBillingAddress: Yup.string().notRequired().email(t("errorEmail")),
    selectEmergencyContact: Yup.string().oneOf(
      ["registerEmergencyContact", "notRegisterEmergencyContact"],
      t("errorSelectRegisterEmergencyContact")
    ),
    firstEmergencyContactPhoneNumber: Yup.string().when(
      "selectEmergencyContact",
      {
        is: (val) => val === "registerEmergencyContact",
        then: Yup.string()
          .required(t("errorRequired"))
          .matches(
            /^[0-9]{10,11}$/,
            t("errorMatchEmergencyContactPhoneNumberFormat")
          ),
        otherwise: Yup.string().notRequired(),
      }
    ),
    firstEmergencyContactPersonName: Yup.string().when(
      "selectEmergencyContact",
      {
        is: (val) => val === "registerEmergencyContact",
        then: Yup.string()
          .required(t("errorRequired"))
          .matches(
            /^[\u30A0-\u30FF]+$/,
            t("errorMatchEmergencyContactPersonNameFormat")
          ),
        otherwise: Yup.string().notRequired(),
      }
    ),
    secondEmergencyContactPhoneNumber: Yup.string().matches(/^[0-9]{10,11}$/, {
      message: t("errorMatchEmergencyContactPhoneNumberFormat"),
      excludeEmptyString: true,
    }),
    secondEmergencyContactPersonName: Yup.string().when(
      "secondEmergencyContactPhoneNumber",
      {
        is: (val) => val.length > 0,
        then: Yup.string()
          .required(t("errorRequired"))
          .matches(
            /^[\u30A0-\u30FF]+$/,
            t("errorMatchEmergencyContactPersonNameFormat")
          ),
        otherwise: Yup.string().notRequired(),
      }
    ),
    thirdEmergencyContactPhoneNumber: Yup.string().matches(/^[0-9]{10,11}$/, {
      message: t("errorMatchEmergencyContactPhoneNumberFormat"),
      excludeEmptyString: true,
    }),
    thirdEmergencyContactPersonName: Yup.string().when(
      "thirdEmergencyContactPhoneNumber",
      {
        is: (val) => val.length > 0,
        then: Yup.string()
          .required(t("errorRequired"))
          .matches(
            /^[\u30A0-\u30FF]+$/,
            t("errorMatchEmergencyContactPersonNameFormat")
          ),
        otherwise: Yup.string().notRequired(),
      }
    ),
    enableCloudTrail: Yup.boolean().oneOf([true], t("errorAgreement")),
    enableAwsConfig: Yup.boolean().oneOf([true], t("errorAgreement")),
  });
}
