import { ListItemIcon, Box, Link, Typography } from "@mui/material";
import { useForm, useWatch } from "react-hook-form";
import React, { useContext, useEffect, useState } from "react";
import { UserInputData } from "./Content";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import CustomTooltip from "components/common/CustomTooltip";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import {
  FormProvider,
  RHFTextField,
  RHFCheckbox,
  RHFSelectBox,
  RHFRadioGroup,
} from "components/hook-form";
import FormHeader from "components/common/FormHeader";
import PaginationBox from "components/common/PaginationBox";
import { useAccountSchema } from "schemas/ecam/member";

const defaultValues = {
  subscriptionType: "",
  accountId: "",
  masterAccountId: "",
  applicationNumber: "",
  createUser: "",
  accountName: "",
  techAddress: "",
  ccTechAddress: "",
  securityAddress: "",
  ccSecurityAddress: "",
  billingAddress: "",
  ccBillingAddress: "",
  selectEmergencyContact: "",
  firstEmergencyContactPhoneNumber: "",
  firstEmergencyContactPersonName: "",
  secondEmergencyContactPhoneNumber: "",
  secondEmergencyContactPersonName: "",
  thirdEmergencyContactPhoneNumber: "",
  thirdEmergencyContactPersonName: "",
  enableCloudTrail: false,
  enableAwsConfig: false,
  selectControlTower: "",
  organizationalUnits: "",
  ssoUserLastName: "",
  ssoUserFirstName: "",
  ssoUserAddress: "",
};

function Account(props) {
  const { t } = useTranslation();
  const accountSchema = useAccountSchema();
  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(accountSchema),
    defaultValues,
  });
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { isValid },
  } = methods;
  const { currentState, setCurrentState, isChecking } =
    useContext(UserInputData);
  const [isNew, setIsNew] = useState(false);
  const [isTransfer, setIsTransfer] = useState(false);
  const [isNeedAlert, setIsNeedAlert] = useState(false);
  const [isReceive, setIsReceive] = useState(false);
  const [isNeedControlTower, setIsNeedControlTower] = useState(false);

  const watchSelectControlTower = useWatch({
    control,
    name: "selectControlTower",
  });
  useEffect(() => {
    switch (watchSelectControlTower) {
      case "enableControlTower":
        setIsNeedControlTower(true);
        break;
      case "disableControlTower":
        setValue("organizationalUnits", "", { shouldValidate: true });
        setValue("ssoUserLastName", "", { shouldValidate: true });
        setValue("ssoUserFirstName", "", { shouldValidate: true });
        setValue("ssoUserAddress", "", { shouldValidate: true });
        setIsNeedControlTower(false);
        break;
      default:
        break;
    }
  }, [watchSelectControlTower, setValue]);

  const watchSubscriptionType = useWatch({ control, name: "subscriptionType" });
  useEffect(() => {
    switch (watchSubscriptionType) {
      case "receiveAccount":
        setValue("selectControlTower", "", { shouldValidate: true });
        setValue("organizationalUnits", "", { shouldValidate: true });
        setValue("ssoUserLastName", "", { shouldValidate: true });
        setValue("ssoUserFirstName", "", { shouldValidate: true });
        setValue("ssoUserAddress", "", { shouldValidate: true });
        setIsReceive(true);
        setIsTransfer(false);
        setIsNew(false);
        break;
      case "transferAccount":
        setValue("selectControlTower", "", { shouldValidate: true });
        setValue("organizationalUnits", "", { shouldValidate: true });
        setValue("ssoUserLastName", "", { shouldValidate: true });
        setValue("ssoUserFirstName", "", { shouldValidate: true });
        setValue("ssoUserAddress", "", { shouldValidate: true });
        setValue("applicationNumber", "", { shouldValidate: true });
        setValue("createUser", "", { shouldValidate: true });
        setIsTransfer(true);
        setIsReceive(false);
        setIsNew(false);
        break;
      case "newAccount":
        setValue("accountId", "", { shouldValidate: true });
        setValue("applicationNumber", "", { shouldValidate: true });
        setValue("createUser", "", { shouldValidate: true });
        setIsNew(true);
        setIsTransfer(false);
        setIsReceive(false);
        break;
      default:
        break;
    }
  }, [watchSubscriptionType, setValue]);

  const watchReceiveAlert = useWatch({
    control,
    name: "selectEmergencyContact",
  });
  useEffect(() => {
    if (watchReceiveAlert === "registerEmergencyContact") {
      setIsNeedAlert(true);
    } else {
      setValue("firstEmergencyContactPhoneNumber", "", {
        shouldValidate: true,
      });
      setValue("firstEmergencyContactPersonName", "", { shouldValidate: true });
      setValue("secondEmergencyContactPhoneNumber", "", {
        shouldValidate: true,
      });
      setValue("secondEmergencyContactPersonName", "", {
        shouldValidate: true,
      });
      setValue("thirdEmergencyContactPhoneNumber", "", {
        shouldValidate: true,
      });
      setValue("thirdEmergencyContactPersonName", "", { shouldValidate: true });
      setIsNeedAlert(false);
    }
  }, [watchReceiveAlert, setValue]);

  const account = currentState["Account"];
  useEffect(() => {
    if (account) {
      for (const k of Object.keys(getValues())) {
        setValue(k, account[k], { shouldValidate: true });
      }
    }
  }, [account, getValues, setValue]);

  const onSubmit = (action) => {
    switch (action) {
      case "back":
        props.handleBack();
        break;
      case "ok":
        props.handleConfirm();
        break;
      default:
        props.handleNext();
    }
    const data = getValues();
    setCurrentState({ ...currentState, Account: data });
  };
  return (
    <>
      <FormHeader
        label={t("accountLabel")}
        description={t("accountDescription")}
      />
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        {/* 契約種別 */}
        <RHFSelectBox
          name="subscriptionType"
          label={t("subscriptionTypeLabel")}
          items={[
            {
              value: "newAccount",
              label: t("newAccountLabel"),
            },
            {
              value: "transferAccount",
              label: t("transferAccountLabel"),
            },
            {
              value: "receiveAccount",
              label: t("receiveAccountLabel"),
            },
          ]}
        />
        {isReceive ? (
          <>
            {/* 名義変更対象の AWS アカウントID */}
            <RHFTextField name="accountId" label={t("receiveAccountIdLabel")} />
            {/* 譲渡元の申請番号 */}
            <RHFTextField
              name="applicationNumber"
              label={t("applicationNumberLabel")}
              placeholder={t("applicationNumberPlaceholder")}
            />
            <CustomTooltip title={t("applicationNumberTooltipTitle")} />
            {/* IAM ユーザーの再作成 */}
            <RHFSelectBox
              name="createUser"
              label={t("createUserLabel")}
              items={[
                {
                  value: "notRequired",
                  label: t("notRequiredLabel"),
                },
                {
                  value: "required",
                  label: t("requiredLabel"),
                },
              ]}
            />
            <CustomTooltip title={t("createUserTooltipTitle")} />
          </>
        ) : (
          <></>
        )}
        {isTransfer ? (
          <>
            {/* 移管対象の AWS アカウントID */}
            <RHFTextField name="accountId" label={t("accountIdLabel")} />
            <Box>
              <Typography variant="body2" color="textSecondary">
                {t("accountIdDescription")}
              </Typography>
            </Box>
          </>
        ) : (
          <></>
        )}
        {/* AWS アカウント名 */}
        <RHFTextField
          name="accountName"
          label={t("accountNameLabel")}
          placeholder={t("accountNamePlaceholder")}
        />
        <CustomTooltip title={t("accountNameTooltipTitle")} />
        {/* 技術通知先メアド TO */}
        <RHFTextField
          name="techAddress"
          label={t("techAddressLabel")}
          placeholder={t("techAddressPlaceholder")}
        />
        <CustomTooltip title={t("techAddressTooltipTitle")} />
        {/* 技術通知先メアド CC */}
        <RHFTextField
          name="ccTechAddress"
          label={t("ccTechAddressLabel")}
          placeholder={t("techAddressPlaceholder")}
        />
        <CustomTooltip title={t("ccTechAddressTooltipTitle")} />
        {/* セキュリティ通知先メアド TO */}
        <RHFTextField
          name="securityAddress"
          label={t("securityAddressLabel")}
          placeholder={t("securityAddressPlaceholder")}
        />
        <CustomTooltip title={t("securityAddressTooltipTitle")} />
        {/* セキュリティ通知先メアド CC */}
        <RHFTextField
          name="ccSecurityAddress"
          label={t("ccSecurityAddressLabel")}
          placeholder={t("securityAddressPlaceholder")}
        />
        <CustomTooltip title={t("ccSecurityAddressTooltipTitle")} />
        {/* 料金通知先メアド TO */}
        <RHFTextField
          name="billingAddress"
          label={t("billingAddressLabel")}
          placeholder={t("billingAddressPlaceholder")}
        />
        <CustomTooltip title={t("billingAddressTooltipTitle")} />
        {/* 料金通知先メアド CC */}
        <RHFTextField
          name="ccBillingAddress"
          label={t("ccBillingAddressLabel")}
          placeholder={t("billingAddressPlaceholder")}
        />
        <CustomTooltip title={t("ccBillingAddressTooltipTitle")} />
        {/* 管理アカウントID */}
        <RHFTextField
          name="masterAccountId"
          label={t("masterAccountIdLabel")}
          placeholder={t("masterAccountIdPlaceholder")}
        />
        <CustomTooltip title={t("ecamMasterAccountIdTooltipTitle")} />
        {/* 緊急連絡先の登録有無 */}
        <RHFRadioGroup
          name="selectEmergencyContact"
          options={[
            {
              value: "registerEmergencyContact",
              label: t("registerEmergencyContactLabel"),
            },
            {
              value: "notRegisterEmergencyContact",
              label: t("notRegisterEmergencyContactLabel"),
            },
          ]}
        />
        <CustomTooltip
          title={t("selectEmergencyContactTooltipTitle")}
          top={-35}
        />
        {isNeedAlert ? (
          <>
            {/* 緊急連絡先の電話番号＆担当者名カナ　優先1 */}
            <RHFTextField
              name="firstEmergencyContactPhoneNumber"
              label={t("firstEmergencyContactPhoneNumberLabel")}
              placeholder={t("emergencyContactPhoneNumberPlaceholder")}
            />
            <CustomTooltip
              title={t("emergencyContactPhoneNumberTooltipTitle")}
            />
            <RHFTextField
              name="firstEmergencyContactPersonName"
              label={t("firstEmergencyContactPersonNameLabel")}
            />
            {/* 緊急連絡先の電話番号＆担当者名カナ　優先2 */}
            <RHFTextField
              name="secondEmergencyContactPhoneNumber"
              label={t("secondEmergencyContactPhoneNumberLabel")}
              placeholder={t("emergencyContactPhoneNumberPlaceholder")}
            />
            <RHFTextField
              name="secondEmergencyContactPersonName"
              label={t("secondEmergencyContactPersonNameLabel")}
            />
            {/* 緊急連絡先の電話番号＆担当者名カナ　優先3 */}
            <RHFTextField
              name="thirdEmergencyContactPhoneNumber"
              label={t("thirdEmergencyContactPhoneNumberLabel")}
              placeholder={t("emergencyContactPhoneNumberPlaceholder")}
            />
            <RHFTextField
              name="thirdEmergencyContactPersonName"
              label={t("thirdEmergencyContactPersonNameLabel")}
            />
          </>
        ) : (
          ""
        )}
        {/* AWS CloudTrail 有効化の同意 */}
        <RHFCheckbox
          name="enableCloudTrail"
          label={t("enableCloudTrailLabel")}
        />
        <CustomTooltip title={t("enableCloudTrailTooltipTitle")} top={-35} />
        {/* AWS Config 有効化の同意 */}
        <RHFCheckbox name="enableAwsConfig" label={t("enableAwsConfigLabel")} />
        <CustomTooltip title={t("enableAwsConfigTooltipTitle")} top={-35} />
        {isNew ? (
          <>
            {/* メンバーアカウントの発行 */}
            <RHFSelectBox
              name="selectControlTower"
              label={t("selectControlTowerLabel")}
              items={[
                {
                  value: "disableControlTower",
                  label: t("disableControlTowerLabel"),
                },
                {
                  value: "enableControlTower",
                  label: t("enableControlTowerLabel"),
                },
              ]}
            />
            <CustomTooltip title={t("selectControlTowerTooltipTitle")} />
            {isNeedControlTower ? (
              <>
                <Box>
                  <ListItemIcon>
                    <ChevronRightOutlinedIcon />
                    <Link
                      target="_blank"
                      href={`https://helpdesk.classmethod.net/hc/articles/17502331306649`}
                    >
                      {t("enableControlTowerNoteLabel")}
                    </Link>
                  </ListItemIcon>
                </Box>
                {/* 適用先 OUs（Organizational Units） */}
                <RHFTextField
                  name="organizationalUnits"
                  label={t("organizationalUnitsLabel")}
                />
                <CustomTooltip title={t("organizationalUnitsTooltipTitle")} />
                {/* AWS IAM Identity Center ユーザーのメアド */}
                <RHFTextField
                  name="ssoUserAddress"
                  label={t("ssoUserAddressLabel")}
                />
                <CustomTooltip title={t("ssoUserAddressTooltipTitle")} />
                {/* AWS IAM Identity Center ユーザー（姓） */}
                <RHFTextField
                  name="ssoUserLastName"
                  label={t("ssoUserLastNameLabel")}
                />
                {/* AWS IAM Identity Center ユーザー（名） */}
                <RHFTextField
                  name="ssoUserFirstName"
                  label={t("ssoUserFirstNameLabel")}
                />
              </>
            ) : (
              <></>
            )}
          </>
        ) : (
          <></>
        )}
        <PaginationBox
          isChecking={isChecking}
          isValid={isValid}
          onSubmit={onSubmit}
        />
      </FormProvider>
    </>
  );
}

export default Account;
