import { Grid, Button, Box } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function PaginationBox({
  isChecking,
  isValid = true,
  onSubmit,
}) {
  const { t } = useTranslation();

  return (
    <Box mt={4} className="mb60">
      {isChecking ? (
        <Grid container spacing={1} justifyContent="flex-end">
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              type="button"
              onClick={() => onSubmit("ok")}
              disabled={!isValid}
            >
              {t("okButtonLabel")}
            </Button>
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={1} justifyContent="space-between">
          <Grid item>
            <Button
              variant="contained"
              color="inherit"
              onClick={() => onSubmit("back")}
            >
              {t("backButtonLabel")}
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" color="primary" type="submit">
              {t("nextButtonLabel")}
            </Button>
          </Grid>
        </Grid>
      )}
    </Box>
  );
}
