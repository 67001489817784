import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

function NotFound() {
  return (
    <Grid container alignItems="center" justifyContent="center">
      <Box mt={10}>
        <Typography
          sx={{ fontSize: "10rem", color: "#D3B735", textAlign: "center" }}
        >
          404
        </Typography>
        <Typography sx={{ fontSize: "2rem", textAlign: "center" }}>
          お探しのページは見つかりませんでした
        </Typography>
      </Box>
    </Grid>
  );
}

export default NotFound;
