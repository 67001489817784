import PropTypes from "prop-types";
import { useFormContext, Controller } from "react-hook-form";
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  RadioGroup,
  Radio,
} from "@mui/material";

RHFRadioGroup.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
};

export default function RHFRadioGroup({ name, options, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange }, fieldState }) => {
        return (
          <>
            <FormControl error={fieldState.invalid}>
              <RadioGroup
                row
                onChange={onChange}
                value={value === undefined ? "" : value}
              >
                {options.map((option) => (
                  <FormControlLabel
                    value={option.value}
                    key={option.value}
                    control={<Radio color="primary" />}
                    label={option.label}
                    labelPlacement="end"
                    {...other}
                  />
                ))}
              </RadioGroup>
              <FormHelperText>{fieldState.error?.message}</FormHelperText>
            </FormControl>
          </>
        );
      }}
    />
  );
}
