import { Grid } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import React, { useContext, useEffect } from "react";
import TextField from "@mui/material/TextField";
import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import { UserInputData } from "./Content";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";

const getApplicantSchema = ({ t, i18n }) => {
  if (i18n.language === "en") {
    return Yup.object().shape({
      lastName: Yup.string().required(t("errorRequired")),
      firstName: Yup.string().required(t("errorRequired")),
      applicantAddress: Yup.string()
        .required(t("errorRequired"))
        .email(t("errorEmail")),
      mobilePhoneNumber: Yup.string().matches(/^\+?[1-9]\d{1,14}$/, {
        message: t("errorMatchPhoneNumber"),
        excludeEmptyString: true,
      }),
    });
  } else {
    return Yup.object().shape({
      lastName: Yup.string().required(t("errorRequired")),
      firstName: Yup.string().required(t("errorRequired")),
      applicantAddress: Yup.string()
        .required(t("errorRequired"))
        .email(t("errorEmail")),
      mobilePhoneNumber: Yup.string().matches(/^[0-9]{11,11}$/, {
        message: t("errorMatchMobilePhoneNumber"),
        excludeEmptyString: true,
      }),
    });
  }
};

function Applicant(props) {
  const { t, i18n } = useTranslation();
  const applicantSchema = getApplicantSchema({ t, i18n });
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      firstName: "",
      lastName: "",
      applicantAddress: "",
      mobilePhoneNumber: "",
      departmentName: "",
    },
    resolver: yupResolver(applicantSchema),
  });
  const { currentState, setCurrentState, isChecking } =
    useContext(UserInputData);

  const applicant = currentState["Applicant"];
  useEffect(() => {
    if (applicant) {
      for (const k of Object.keys(getValues())) {
        setValue(k, applicant[k], { shouldValidate: true });
      }
    }
  }, [applicant, getValues, setValue]);

  const onSubmit = (action) => {
    switch (action) {
      case "back":
        props.handleBack();
        break;
      case "ok":
        props.handleConfirm();
        break;
      default:
        props.handleNext();
    }
    const data = getValues();
    setCurrentState({ ...currentState, Applicant: data });
  };
  return (
    <>
      <Typography gutterBottom variant="h4" component="h2">
        {t("applicantLabel")}
      </Typography>
      <Typography variant="body2" color="textSecondary">
        {t("applicantDescription")}
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          name="firstName"
          render={({ field }) => (
            <TextField
              {...field}
              label={t("firstNameLabel")}
              error={errors.firstName ? true : false}
              helperText={errors.firstName?.message}
              fullWidth
              margin="normal"
            />
          )}
        />
        <Controller
          control={control}
          name="lastName"
          render={({ field }) => (
            <TextField
              {...field}
              label={t("lastNameLabel")}
              error={errors.lastName ? true : false}
              helperText={errors.lastName?.message}
              fullWidth
              margin="normal"
            />
          )}
        />
        <Controller
          control={control}
          name="applicantAddress"
          render={({ field }) => (
            <TextField
              {...field}
              label={t("applicantAddressLabel")}
              error={errors.applicantAddress ? true : false}
              helperText={errors.applicantAddress?.message}
              fullWidth
              margin="normal"
            />
          )}
        />
        <Controller
          control={control}
          name="mobilePhoneNumber"
          render={({ field }) => (
            <TextField
              {...field}
              label={t("mobilePhoneNumberLabel")}
              error={errors.mobilePhoneNumber ? true : false}
              helperText={errors.mobilePhoneNumber?.message}
              fullWidth
              margin="normal"
              placeholder={t("mobilePhoneNumberPlaceholder")}
            />
          )}
        />
        <Controller
          control={control}
          name="departmentName"
          render={({ field }) => (
            <TextField
              {...field}
              label={t("departmentNameLabel")}
              error={errors.departmentName ? true : false}
              helperText={errors.departmentName?.message}
              fullWidth
              margin="normal"
            />
          )}
        />
        <Box mt={4} className="mb60">
          {isChecking ? (
            <Grid container spacing={1} justifyContent="flex-end">
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  type="button"
                  onClick={() => onSubmit("ok")}
                  disabled={!isValid}
                >
                  {t("okButtonLabel")}
                </Button>
              </Grid>
            </Grid>
          ) : (
            <Grid container spacing={1} justifyContent="space-between">
              <Grid item>
                <Button
                  variant="contained"
                  color="inherit"
                  onClick={() => onSubmit("back")}
                >
                  {t("backButtonLabel")}
                </Button>
              </Grid>
              <Grid item>
                <Button variant="contained" color="primary" type="submit">
                  {t("nextButtonLabel")}
                </Button>
              </Grid>
            </Grid>
          )}
        </Box>
      </form>
    </>
  );
}

export default Applicant;
