import { useForm } from "react-hook-form";
import React, { useContext, useEffect } from "react";
import { UserInputData } from "./Content";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, RHFTextField } from "components/hook-form";
import FormHeader from "components/common/FormHeader";
import PaginationBox from "components/common/PaginationBox";
import { useTransfereeSchema } from "schemas/contract/Transferee";

const defaultValues = {
  cancelCompanyName: "",
  adminName: "",
  cancelAdminAddress: "",
};

function Transferee(props) {
  const { t } = useTranslation();
  const transfereeSchema = useTransfereeSchema();
  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(transfereeSchema),
    defaultValues,
  });
  const {
    handleSubmit,
    setValue,
    getValues,
    formState: { isValid },
  } = methods;

  const { currentState, setCurrentState, isChecking } =
    useContext(UserInputData);

  const transfer = currentState["Transferee"];
  useEffect(() => {
    if (transfer) {
      for (const k of Object.keys(getValues())) {
        setValue(k, transfer[k], { shouldValidate: true });
      }
    }
  }, [transfer, getValues, setValue]);
  const onSubmit = (action) => {
    if (action === "back") {
      props.handleBack();
    } else if (isChecking) {
      props.handleConfirm();
    } else {
      props.handleNext();
    }
    const data = getValues();
    setCurrentState({ ...currentState, Transferee: data });
  };
  return (
    <>
      <FormHeader
        label={t("transfereeLabel")}
        description={t("transferDescription")}
      />
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <RHFTextField
          name="cancelCompanyName"
          label={t("cancelCompanyNameLabel")}
          placeholder={t("companyNamePlaceholder")}
        />
        <RHFTextField name="adminName" label={t("adminNameLabel")} />
        <RHFTextField
          name="cancelAdminAddress"
          label={t("cancelAdminAddressLabel")}
          placeholder={t("adminAddressPlaceholder")}
        />
        <PaginationBox
          isChecking={isChecking}
          isValid={isValid}
          onSubmit={onSubmit}
        />
      </FormProvider>
    </>
  );
}

export default Transferee;
