import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "auth";

function RequireAuth({ children }) {
  let auth = useAuth();
  let location = useLocation();
  if (!auth.isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} />;
  }
  return children;
}

export default RequireAuth;
