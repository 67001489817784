import * as Yup from "yup";
import { useTranslation } from "react-i18next";

export function useTransfereeSchema() {
  const { t } = useTranslation();
  return Yup.object().shape({
    cancelCompanyName: Yup.string().required(t("errorRequired")),
    adminName: Yup.string().required(t("errorRequired")),
    cancelAdminAddress: Yup.string()
      .required(t("errorRequired"))
      .email(t("errorEmail")),
  });
}
