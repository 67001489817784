import {
  Grid,
  TextField,
  Button,
  Box,
  Typography,
  List,
  ListItemIcon,
  Autocomplete,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { useContext, useEffect, useState } from "react";
import { UserInputData } from "./Content";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { useAuth } from "auth";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import CustomTooltip from "components/common/CustomTooltip";
import { FormProvider, RHFCheckbox } from "components/hook-form";
import FormHeader from "components/common/FormHeader";
import { useContractSchema } from "schemas/contract/Account";

const defaultValues = {
  changeAccounts: [],
  precautionsAssociatedOwnerChange: false,
};

function Contract(props) {
  const { accessToken, orgId } = useAuth();
  const { t } = useTranslation();
  const contractSchema = useContractSchema();
  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(contractSchema),
    defaultValues,
  });
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors, isValid },
  } = methods;
  const { currentState, setCurrentState, isChecking } =
    useContext(UserInputData);
  const [awsAccounts, setAwsAccounts] = useState([]);

  useEffect(() => {
    const getAccounts = async (accessToken) => {
      try {
        const res = await fetch(
          process.env.REACT_APP_CMP_ENDPOINT +
            `/aws-accounts?enabled=true&size=1000&organizationId=${orgId}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        return await res.json();
      } catch (error) {
        console.error(error);
        throw error;
      }
    };
    getAccounts(accessToken)
      .then((res) => {
        if (res.hasOwnProperty("_embedded")) {
          setAwsAccounts(res?._embedded?.awsAccounts);
        } else {
          // AWS アカウントが存在しない場合
          // 解約対象の AWS アカウントフィールドをリセット
          setAwsAccounts([]);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, [accessToken, orgId]);

  const require = currentState["Account"];
  useEffect(() => {
    if (require) {
      for (const k of Object.keys(getValues())) {
        if (k !== "changeAccounts") {
          setValue(k, require[k], { shouldValidate: true });
        }
      }
    }
  }, [require, getValues, setValue]);

  const onSubmit = (action) => {
    switch (action) {
      case "back":
        props.handleBack();
        break;
      case "ok":
        props.handleConfirm();
        break;
      default:
        props.handleNext();
    }
    const data = getValues();
    // AWS アカウントID だけ抽出する
    data.changeAccounts = data?.changeAccounts?.map((obj) => obj.accountId);
    // プロジェクトID は不要なため削除
    delete data.projectId;
    setCurrentState({ ...currentState, Account: data });
  };
  return (
    <>
      <FormHeader
        label={t("accountLabel")}
        description={t("contractChangeDescription")}
      />
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          name="changeAccounts"
          render={({ props }) => (
            <Autocomplete
              multiple
              options={awsAccounts}
              getOptionLabel={(i) =>
                i["accountId"] + "（" + i["accountName"] + "）"
              }
              filterSelectedOptions
              renderInput={(props) => (
                <TextField
                  {...props}
                  label={t("changeAccountsLabel")}
                  error={errors.changeAccounts ? true : false}
                  helperText={errors.changeAccounts?.message}
                  fullWidth
                  margin="normal"
                  key={props.id}
                />
              )}
              onChange={(event, value) => {
                setValue("changeAccounts", value, { shouldValidate: true });
              }}
            />
          )}
        />
        <CustomTooltip title={t("multiAccountsTooltipTitle")} />
        <Typography variant="body1" color="textSecondary">
          {t("precautionsForAccountOwnerChangeLabel")}
        </Typography>
        <List>
          <ListItemIcon>
            <ChevronRightOutlinedIcon />
            <Typography variant="body2" color="textSecondary">
              {t("precautionsForAccountOwnerChangeFirstLabel")}
            </Typography>
          </ListItemIcon>
          <ListItemIcon>
            <ChevronRightOutlinedIcon />
            <Typography variant="body2" color="textSecondary">
              {t("precautionsForAccountOwnerChangeSecondLabel")}
            </Typography>
          </ListItemIcon>
        </List>
        <RHFCheckbox
          name="precautionsAssociatedOwnerChange"
          label={t("precautionsAssociatedOwnerChangeLabel")}
        />
        <Box mt={4} className="mb60">
          {isChecking ? (
            <Grid container spacing={1} justifyContent="flex-end">
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  type="button"
                  onClick={() => onSubmit("ok")}
                  disabled={!isValid}
                >
                  {t("okButtonLabel")}
                </Button>
              </Grid>
            </Grid>
          ) : (
            <Grid container spacing={1} justifyContent="flex-end">
              <Grid item>
                <Button variant="contained" color="primary" type="submit">
                  {t("nextButtonLabel")}
                </Button>
              </Grid>
            </Grid>
          )}
        </Box>
      </FormProvider>
    </>
  );
}

export default Contract;
