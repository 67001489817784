import { Grid, TextField, Button, Box, Autocomplete } from "@mui/material";
import { useForm, Controller, useWatch } from "react-hook-form";
import React, { useContext, useEffect, useState } from "react";
import { UserInputData } from "./Content";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { useAuth } from "auth";
import { FormProvider, RHFRadioGroup } from "components/hook-form";
import FormHeader from "components/common/FormHeader";
import { useProjectSchema } from "schemas/contract/Project";

const defaultValues = {
  requestType: "projectIntegration",
  targetProjectId: "",
  targetAccounts: [],
  destinationProjectName: "",
  destinationProjectId: "",
  invoicePersonLastName: "",
  invoicePersonFirstName: "",
  invoiceMailAddress: "",
};

function Billing(props) {
  const { accessToken, orgId, projects } = useAuth();
  const { t } = useTranslation();
  const projectSchema = useProjectSchema();
  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(projectSchema),
    defaultValues,
  });
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors, isValid },
  } = methods;
  const { currentState, setCurrentState, isChecking } =
    useContext(UserInputData);

  const [currentProjectId, setCurrentProjectId] = useState(0);
  const [awsAccounts, setAwsAccounts] = useState([]);
  const [dstProjects, setDstProjects] = useState([]);
  const [resetTrigger, setResetTrigger] = useState(false);

  const watchProjectId = useWatch({ control, name: "targetProjectId" });
  useEffect(() => {
    const getAccounts = async (accessToken, orgId, prjId) => {
      try {
        const res = await fetch(
          process.env.REACT_APP_CMP_ENDPOINT +
            `/orgs/${orgId}/projects/${prjId}/aws-accounts?enabled=true&size=1000`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        return await res.json();
      } catch (error) {
        console.error(error);
        throw error;
      }
    };
    if (watchProjectId > 0) {
      setCurrentProjectId(watchProjectId);
      if (watchProjectId !== currentProjectId) {
        switch (resetTrigger) {
          case true:
            setResetTrigger(false);
            break;
          case false:
            setResetTrigger(true);
            break;
          default:
            break;
        }
        setValue("targetAccounts", []);
        setValue("destinationProjectId", "");
        setAwsAccounts([]);
        getAccounts(accessToken, orgId, watchProjectId)
          .then((res) => {
            if (res.hasOwnProperty("_embedded")) {
              setAwsAccounts(res?._embedded?.awsAccounts);
            } else {
              // 対象の CMP プロジェクトに有効な AWS アカウントが存在しない場合
              // 対象の AWS アカウントフィールドをリセット
              setAwsAccounts([]);
            }
          })
          .catch((err) => {
            console.error(err);
          });
        for (const p of projects) {
          if (p.id === watchProjectId) {
            let tmp = [];
            switch (p.projectType) {
              case "MEMBERS":
              case "V2_PREMIUM":
                tmp = projects.filter((p) => watchProjectId !== p.id);
                tmp = tmp.filter(
                  (p) =>
                    ["V2_PREMIUM", "MEMBERS"].includes(p.projectType) === true
                );
                setDstProjects(tmp);
                break;
              case "EUROPE":
                tmp = projects.filter((p) => watchProjectId !== p.id);
                tmp = tmp.filter(
                  (p) => ["EUROPE"].includes(p.projectType) === true
                );
                setDstProjects(tmp);
                break;
              case "CANADA":
                tmp = projects.filter((p) => watchProjectId !== p.id);
                tmp = tmp.filter(
                  (p) => ["CANADA"].includes(p.projectType) === true
                );
                setDstProjects(tmp);
                break;
              case "THAI":
                tmp = projects.filter((p) => watchProjectId !== p.id);
                tmp = tmp.filter(
                  (p) => ["THAI"].includes(p.projectType) === true
                );
                setDstProjects(tmp);
                break;
              case "KOREA":
                tmp = projects.filter((p) => watchProjectId !== p.id);
                tmp = tmp.filter(
                  (p) => ["KOREA"].includes(p.projectType) === true
                );
                setDstProjects(tmp);
                break;
              case "INDIA":
                tmp = projects.filter((p) => watchProjectId !== p.id);
                tmp = tmp.filter(
                  (p) => ["INDIA"].includes(p.projectType) === true
                );
                setDstProjects(tmp);
                break;
              case "V2_VOUCHER":
                tmp = projects.filter((p) => watchProjectId !== p.id);
                tmp = tmp.filter(
                  (p) => ["V2_VOUCHER"].includes(p.projectType) === true
                );
                setDstProjects(tmp);
                break;
              case "UNIVCOOP_VOUCHER":
                tmp = projects.filter((p) => watchProjectId !== p.id);
                tmp = tmp.filter(
                  (p) => ["UNIVCOOP_VOUCHER"].includes(p.projectType) === true
                );
                setDstProjects(tmp);
                break;
              case "V2_MEMBERS":
              case "START":
                tmp = projects.filter((p) => watchProjectId !== p.id);
                tmp = tmp.filter(
                  (p) =>
                    ["V2_MEMBERS", "START", "MEMBERS", "V2_PREMIUM"].includes(
                      p.projectType
                    ) === true
                );
                setDstProjects(tmp);
                break;
              case "ORG_CDN_DISCOUNT_PREMIUM":
                tmp = projects.filter((p) => watchProjectId !== p.id);
                tmp = tmp.filter(
                  (p) =>
                    ["ORG_CDN_DISCOUNT_PREMIUM"].includes(p.projectType) ===
                    true
                );
                setDstProjects(tmp);
                break;
              case "CDN_DISCOUNT_PREMIUM":
                tmp = projects.filter((p) => watchProjectId !== p.id);
                tmp = tmp.filter(
                  (p) =>
                    ["CDN_DISCOUNT_PREMIUM"].includes(p.projectType) === true
                );
                setDstProjects(tmp);
                break;
              case "ORG_PRIVATE_DISCOUNT_PREMIUM":
                tmp = projects.filter((p) => watchProjectId !== p.id);
                tmp = tmp.filter(
                  (p) =>
                    ["ORG_PRIVATE_DISCOUNT_PREMIUM"].includes(p.projectType) ===
                    true
                );
                setDstProjects(tmp);
                break;
              case "ORGANIZATION_PREMIUM":
                tmp = projects.filter((p) => watchProjectId !== p.id);
                tmp = tmp.filter(
                  (p) =>
                    ["ORGANIZATION_PREMIUM"].includes(p.projectType) === true
                );
                setDstProjects(tmp);
                break;
              case "ORG_FLATRATE_DISCOUNT_PREMIUM":
                tmp = projects.filter((p) => watchProjectId !== p.id);
                tmp = tmp.filter(
                  (p) =>
                    ["ORG_FLATRATE_DISCOUNT_PREMIUM"].includes(
                      p.projectType
                    ) === true
                );
                setDstProjects(tmp);
                break;
              case "ORG_FLATRATE_DISCOUNT_4_PREMIUM":
                tmp = projects.filter((p) => watchProjectId !== p.id);
                tmp = tmp.filter(
                  (p) =>
                    ["ORG_FLATRATE_DISCOUNT_4_PREMIUM"].includes(
                      p.projectType
                    ) === true
                );
                setDstProjects(tmp);
                break;
              case "ORG_FLATRATE_DISCOUNT_5_PREMIUM":
                tmp = projects.filter((p) => watchProjectId !== p.id);
                tmp = tmp.filter(
                  (p) =>
                    ["ORG_FLATRATE_DISCOUNT_5_PREMIUM"].includes(
                      p.projectType
                    ) === true
                );
                setDstProjects(tmp);
                break;
              case "ORG_CDN_DISCOUNT":
                tmp = projects.filter((p) => watchProjectId !== p.id);
                tmp = tmp.filter(
                  (p) =>
                    ["ORG_CDN_DISCOUNT", "ORG_CDN_DISCOUNT_PREMIUM"].includes(
                      p.projectType
                    ) === true
                );
                setDstProjects(tmp);
                break;
              case "CDN_DISCOUNT":
                tmp = projects.filter((p) => watchProjectId !== p.id);
                tmp = tmp.filter(
                  (p) =>
                    ["CDN_DISCOUNT", "CDN_DISCOUNT_PREMIUM"].includes(
                      p.projectType
                    ) === true
                );
                setDstProjects(tmp);
                break;
              case "ORG_PRIVATE_DISCOUNT":
                tmp = projects.filter((p) => watchProjectId !== p.id);
                tmp = tmp.filter(
                  (p) =>
                    [
                      "ORG_PRIVATE_DISCOUNT",
                      "ORG_PRIVATE_DISCOUNT_PREMIUM",
                    ].includes(p.projectType) === true
                );
                setDstProjects(tmp);
                break;
              case "ORGANIZATION":
                tmp = projects.filter((p) => watchProjectId !== p.id);
                tmp = tmp.filter(
                  (p) =>
                    ["ORGANIZATION", "ORGANIZATION_PREMIUM"].includes(
                      p.projectType
                    ) === true
                );
                setDstProjects(tmp);
                break;
              case "ORG_FLATRATE_DISCOUNT":
                tmp = projects.filter((p) => watchProjectId !== p.id);
                tmp = tmp.filter(
                  (p) =>
                    [
                      "ORG_FLATRATE_DISCOUNT",
                      "ORG_FLATRATE_DISCOUNT_PREMIUM",
                    ].includes(p.projectType) === true
                );
                setDstProjects(tmp);
                break;
              case "ORG_FLATRATE_DISCOUNT_2":
                tmp = projects.filter((p) => watchProjectId !== p.id);
                tmp = tmp.filter(
                  (p) =>
                    [
                      "ORG_FLATRATE_DISCOUNT_2",
                      "ORG_FLATRATE_DISCOUNT_2_PREMIUM",
                    ].includes(p.projectType) === true
                );
                setDstProjects(tmp);
                break;
              case "ORG_FLATRATE_DISCOUNT_2_PREMIUM":
                tmp = projects.filter((p) => watchProjectId !== p.id);
                tmp = tmp.filter(
                  (p) =>
                    ["ORG_FLATRATE_DISCOUNT_2_PREMIUM"].includes(
                      p.projectType
                    ) === true
                );
                setDstProjects(tmp);
                break;
              case "ORG_FLATRATE_DISCOUNT_4":
                tmp = projects.filter((p) => watchProjectId !== p.id);
                tmp = tmp.filter(
                  (p) =>
                    [
                      "ORG_FLATRATE_DISCOUNT_4",
                      "ORG_FLATRATE_DISCOUNT_4_PREMIUM",
                    ].includes(p.projectType) === true
                );
                setDstProjects(tmp);
                break;
              case "ORG_FLATRATE_DISCOUNT_5":
                tmp = projects.filter((p) => watchProjectId !== p.id);
                tmp = tmp.filter(
                  (p) =>
                    [
                      "ORG_FLATRATE_DISCOUNT_5",
                      "ORG_FLATRATE_DISCOUNT_5_PREMIUM",
                    ].includes(p.projectType) === true
                );
                setDstProjects(tmp);
                break;
              case "FLATRATE_DISCOUNT_2":
                tmp = projects.filter((p) => watchProjectId !== p.id);
                tmp = tmp.filter(
                  (p) =>
                    [
                      "FLATRATE_DISCOUNT_2",
                      "FLATRATE_DISCOUNT_2_PREMIUM",
                    ].includes(p.projectType) === true
                );
                setDstProjects(tmp);
                break;
              case "FLATRATE_DISCOUNT_2_PREMIUM":
                tmp = projects.filter((p) => watchProjectId !== p.id);
                tmp = tmp.filter(
                  (p) =>
                    ["FLATRATE_DISCOUNT_2_PREMIUM"].includes(p.projectType) ===
                    true
                );
                setDstProjects(tmp);
                break;
              case "FLATRATE_DISCOUNT_6":
                tmp = projects.filter((p) => watchProjectId !== p.id);
                tmp = tmp.filter(
                  (p) =>
                    [
                      "FLATRATE_DISCOUNT_6",
                      "FLATRATE_DISCOUNT_6_PREMIUM",
                    ].includes(p.projectType) === true
                );
                setDstProjects(tmp);
                break;
              case "FLATRATE_DISCOUNT_6_PREMIUM":
                tmp = projects.filter((p) => watchProjectId !== p.id);
                tmp = tmp.filter(
                  (p) =>
                    ["FLATRATE_DISCOUNT_6_PREMIUM"].includes(p.projectType) ===
                    true
                );
                setDstProjects(tmp);
                break;
              case "FLATRATE_DISCOUNT_7":
                tmp = projects.filter((p) => watchProjectId !== p.id);
                tmp = tmp.filter(
                  (p) =>
                    [
                      "FLATRATE_DISCOUNT_7",
                      "FLATRATE_DISCOUNT_7_PREMIUM",
                    ].includes(p.projectType) === true
                );
                setDstProjects(tmp);
                break;
              case "FLATRATE_DISCOUNT_7_PREMIUM":
                tmp = projects.filter((p) => watchProjectId !== p.id);
                tmp = tmp.filter(
                  (p) =>
                    ["FLATRATE_DISCOUNT_7_PREMIUM"].includes(p.projectType) ===
                    true
                );
                setDstProjects(tmp);
                break;
              case "FLATRATE_DISCOUNT_8":
                tmp = projects.filter((p) => watchProjectId !== p.id);
                tmp = tmp.filter(
                  (p) =>
                    [
                      "FLATRATE_DISCOUNT_8",
                      "FLATRATE_DISCOUNT_8_PREMIUM",
                    ].includes(p.projectType) === true
                );
                setDstProjects(tmp);
                break;
              case "FLATRATE_DISCOUNT_8_PREMIUM":
                tmp = projects.filter((p) => watchProjectId !== p.id);
                tmp = tmp.filter(
                  (p) =>
                    ["FLATRATE_DISCOUNT_8_PREMIUM"].includes(p.projectType) ===
                    true
                );
                setDstProjects(tmp);
                break;
              case "FLATRATE_DISCOUNT_9":
                tmp = projects.filter((p) => watchProjectId !== p.id);
                tmp = tmp.filter(
                  (p) =>
                    [
                      "FLATRATE_DISCOUNT_9",
                      "FLATRATE_DISCOUNT_9_PREMIUM",
                    ].includes(p.projectType) === true
                );
                setDstProjects(tmp);
                break;
              case "FLATRATE_DISCOUNT_9_PREMIUM":
                tmp = projects.filter((p) => watchProjectId !== p.id);
                tmp = tmp.filter(
                  (p) =>
                    ["FLATRATE_DISCOUNT_9_PREMIUM"].includes(p.projectType) ===
                    true
                );
                setDstProjects(tmp);
                break;
              case "FLATRATE_DISCOUNT_10":
                tmp = projects.filter((p) => watchProjectId !== p.id);
                tmp = tmp.filter(
                  (p) =>
                    [
                      "FLATRATE_DISCOUNT_10",
                      "FLATRATE_DISCOUNT_10_PREMIUM",
                    ].includes(p.projectType) === true
                );
                setDstProjects(tmp);
                break;
              case "FLATRATE_DISCOUNT_10_PREMIUM":
                tmp = projects.filter((p) => watchProjectId !== p.id);
                tmp = tmp.filter(
                  (p) =>
                    ["FLATRATE_DISCOUNT_10_PREMIUM"].includes(p.projectType) ===
                    true
                );
                setDstProjects(tmp);
                break;
              case "FLATRATE_DISCOUNT_11":
                tmp = projects.filter((p) => watchProjectId !== p.id);
                tmp = tmp.filter(
                  (p) =>
                    [
                      "FLATRATE_DISCOUNT_11",
                      "FLATRATE_DISCOUNT_11_PREMIUM",
                    ].includes(p.projectType) === true
                );
                setDstProjects(tmp);
                break;
              case "FLATRATE_DISCOUNT_11_PREMIUM":
                tmp = projects.filter((p) => watchProjectId !== p.id);
                tmp = tmp.filter(
                  (p) =>
                    ["FLATRATE_DISCOUNT_11_PREMIUM"].includes(p.projectType) ===
                    true
                );
                setDstProjects(tmp);
                break;
              case "FLATRATE_DISCOUNT_12":
                tmp = projects.filter((p) => watchProjectId !== p.id);
                tmp = tmp.filter(
                  (p) =>
                    [
                      "FLATRATE_DISCOUNT_12",
                      "FLATRATE_DISCOUNT_12_PREMIUM",
                    ].includes(p.projectType) === true
                );
                setDstProjects(tmp);
                break;
              case "FLATRATE_DISCOUNT_12_PREMIUM":
                tmp = projects.filter((p) => watchProjectId !== p.id);
                tmp = tmp.filter(
                  (p) =>
                    ["FLATRATE_DISCOUNT_12_PREMIUM"].includes(p.projectType) ===
                    true
                );
                setDstProjects(tmp);
                break;
              case "ORG_PRIVATE_DISCOUNT_1":
                tmp = projects.filter((p) => watchProjectId !== p.id);
                tmp = tmp.filter(
                  (p) =>
                    [
                      "ORG_PRIVATE_DISCOUNT_1",
                      "ORG_PRIVATE_DISCOUNT_1_PREMIUM",
                    ].includes(p.projectType) === true
                );
                setDstProjects(tmp);
                break;
              case "ORG_PRIVATE_DISCOUNT_1_PREMIUM":
                tmp = projects.filter((p) => watchProjectId !== p.id);
                tmp = tmp.filter(
                  (p) =>
                    ["ORG_PRIVATE_DISCOUNT_1_PREMIUM"].includes(
                      p.projectType
                    ) === true
                );
                setDstProjects(tmp);
                break;

              case "ORG_PRIVATE_DISCOUNT_2":
                tmp = projects.filter((p) => watchProjectId !== p.id);
                tmp = tmp.filter(
                  (p) =>
                    [
                      "ORG_PRIVATE_DISCOUNT_2",
                      "ORG_PRIVATE_DISCOUNT_2_PREMIUM",
                    ].includes(p.projectType) === true
                );
                setDstProjects(tmp);
                break;
              case "ORG_PRIVATE_DISCOUNT_2_PREMIUM":
                tmp = projects.filter((p) => watchProjectId !== p.id);
                tmp = tmp.filter(
                  (p) =>
                    ["ORG_PRIVATE_DISCOUNT_2_PREMIUM"].includes(
                      p.projectType
                    ) === true
                );
                setDstProjects(tmp);
                break;
              case "ORG_PRIVATE_DISCOUNT_3":
                tmp = projects.filter((p) => watchProjectId !== p.id);
                tmp = tmp.filter(
                  (p) =>
                    [
                      "ORG_PRIVATE_DISCOUNT_3",
                      "ORG_PRIVATE_DISCOUNT_3_PREMIUM",
                    ].includes(p.projectType) === true
                );
                setDstProjects(tmp);
                break;
              case "ORG_PRIVATE_DISCOUNT_3_PREMIUM":
                tmp = projects.filter((p) => watchProjectId !== p.id);
                tmp = tmp.filter(
                  (p) =>
                    ["ORG_PRIVATE_DISCOUNT_3_PREMIUM"].includes(
                      p.projectType
                    ) === true
                );
                setDstProjects(tmp);
                break;
              default:
                setDstProjects(tmp);
            }
          }
        }
      }
    }
  }, [
    watchProjectId,
    currentProjectId,
    accessToken,
    orgId,
    projects,
    setValue,
    resetTrigger,
  ]);

  const project = currentState["Project"];
  useEffect(() => {
    if (project) {
      for (const k of Object.keys(getValues())) {
        setValue(k, project[k], { shouldValidate: true });
      }
    }
  }, [project, getValues, setValue]);

  const onSubmit = (action) => {
    switch (action) {
      case "back":
        props.handleBack();
        break;
      case "ok":
        props.handleConfirm();
        break;
      default:
        props.handleNext();
    }
    const data = getValues();
    data.targetAccounts = data?.targetAccounts?.map((obj) => obj.accountId);
    delete data.targetProjectId;
    setCurrentState({ ...currentState, Project: data });
  };
  return (
    <>
      <FormHeader
        label={t("projectLabel")}
        description={t("updateProjectDescription")}
      />
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Box sx={{ mt: 3 }}>
          <RHFRadioGroup
            name="requestType"
            options={[
              {
                value: "projectIntegration",
                label: t("projectIntegrationLabel"),
              },
            ]}
          />
        </Box>
        <Controller
          control={control}
          name="targetProjectId"
          render={({ props }) => (
            <Autocomplete
              options={projects}
              getOptionLabel={(project) => project.name}
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option.id}>
                    {option.name}
                  </li>
                );
              }}
              renderInput={(props) => (
                <TextField
                  {...props}
                  label={t("targetProjectIdLabel")}
                  error={errors.targetProjectId ? true : false}
                  helperText={errors.targetProjectId?.message}
                  fullWidth
                  margin="normal"
                  key={props.id}
                />
              )}
              onChange={(event, value) => {
                if (value !== null) {
                  setValue("targetProjectId", value.id, {
                    shouldValidate: true,
                  });
                }
              }}
            />
          )}
        />
        <Controller
          control={control}
          name="targetAccounts"
          render={({ props }) => (
            <Autocomplete
              key={resetTrigger}
              multiple
              options={awsAccounts}
              getOptionLabel={(i) =>
                i["accountId"] + "（" + i["accountName"] + "）"
              }
              filterSelectedOptions
              renderInput={(props) => (
                <TextField
                  {...props}
                  label={t("updateAccountsLabel")}
                  error={errors.targetAccounts ? true : false}
                  helperText={errors.targetAccounts?.message}
                  fullWidth
                  margin="normal"
                  key={props.id}
                />
              )}
              onChange={(event, value) => {
                setValue("targetAccounts", value, { shouldValidate: true });
              }}
            />
          )}
        />
        <>
          <Controller
            control={control}
            name="destinationProjectId"
            render={({ props }) => (
              <Autocomplete
                key={resetTrigger}
                options={dstProjects}
                getOptionLabel={(project) => project.name}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.id}>
                      {option.name}
                    </li>
                  );
                }}
                renderInput={(props) => (
                  <TextField
                    {...props}
                    label={t("destinationProjectIdLabel")}
                    error={errors.destinationProjectId ? true : false}
                    helperText={errors.destinationProjectId?.message}
                    fullWidth
                    margin="normal"
                    key={props.id}
                  />
                )}
                onChange={(event, value) => {
                  if (value !== null) {
                    setValue("destinationProjectId", value.id, {
                      shouldValidate: true,
                    });
                  }
                }}
              />
            )}
          />
        </>
        <Box mt={4} className="mb60">
          {isChecking ? (
            <Grid container spacing={1} justifyContent="flex-end">
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  type="button"
                  onClick={() => onSubmit("ok")}
                  disabled={!isValid}
                >
                  {t("okButtonLabel")}
                </Button>
              </Grid>
            </Grid>
          ) : (
            <Grid container spacing={1} justifyContent="flex-end">
              <Grid item>
                <Button variant="contained" color="primary" type="submit">
                  {t("nextButtonLabel")}
                </Button>
              </Grid>
            </Grid>
          )}
        </Box>
      </FormProvider>
    </>
  );
}

export default Billing;
