import { Grid, Alert, Link } from "@mui/material";

export default function ServiceAlert() {
  return (
    <Grid container spacing={3}>
      <Grid item xs={1} />
      <Grid item xs={10}>
        <Alert severity="error" sx={{ mt: "20px", mb: "10px" }}>
          重要なお知らせ：当申請フォームは、2024年9月30日をもって提供を終了いたします。
        </Alert>
        <Alert
          severity="warning"
          sx={{ mt: "10px", mb: "30px", whiteSpace: "pre-line" }}
        >
          2024年9月30日までに
          <Link
            href="https://members.classmethod.net/pdf/cmp-migration-guide.pdf"
            underline="hover"
          >
            移行ガイド
          </Link>
          に記載された手順に従ってユーザー移行作業をお願いいたします。
          <br />
          なお、ユーザー移行後はリニューアルされたポータルサイトから各種契約のお手続きが可能です。
          <br />
          詳細につきましては
          <Link
            href="https://helpdesk.classmethod.net/hc/ja/articles/30341994124569"
            underline="hover"
          >
            こちら
          </Link>
          をご確認ください。
        </Alert>
      </Grid>
      <Grid item xs={1} />
    </Grid>
  );
}
