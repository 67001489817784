import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import "./i18n";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import TagManager from "react-gtm-module";

const tagManagerArgs = {
  gtmId: "GTM-TFWCDZL",
};

TagManager.initialize(tagManagerArgs);

const container = document.getElementById("root");
const root = createRoot(container);
root.render(<App />);

reportWebVitals();
