import React, { useContext, useState, useEffect } from "react";
import { Button } from "@mui/material";
import { UserInputData } from "./Content";
import toast, { Toaster } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useAuth } from "auth";
import ConfirmTable from "components/common/ConfirmTable";

const pageIndex = {
  Project: 0,
  Others: 1,
};

function Confirm(props) {
  const { accessToken } = useAuth();
  const { t, i18n } = useTranslation();
  const [isSending, setIsSending] = useState(false);
  const { currentState, setCurrentState, setIsChecking } =
    useContext(UserInputData);
  useEffect(() => {
    setIsChecking(true);
  }, [setIsChecking]);
  const notifyError = () => toast.error(t("errorPostData"));
  const onSubmit = () => {
    setIsSending(true);
    postData()
      .then((data) => {
        setCurrentState({ ...currentState, requestId: data["requestId"] });
        props.handleNext();
      })
      .catch((err) => {
        notifyError();
        console.error(err);
        setIsSending(false);
      });
  };
  const editPage = (index) => {
    props.modifyContent(index);
  };
  async function postData() {
    const payload = currentState;
    payload["lang"] = `${i18n.language}`;
    payload["requestType"] = "updateProject";
    try {
      const res = await fetch(
        process.env.REACT_APP_API_ENDPOINT + "/project/update",
        {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify(payload),
        }
      );
      if (!res.ok) {
        throw res.error;
      } else {
        return await res.json();
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
  return (
    <>
      <Toaster position="top-right" duration="10000" />
      <ConfirmTable
        currentState={currentState}
        editPage={editPage}
        pageIndex={pageIndex}
      />
      <Button
        variant="contained"
        color="error"
        onClick={onSubmit}
        disabled={isSending}
        className="Submit-btn"
      >
        {t("submitButtonLabel")}
      </Button>
    </>
  );
}

export default Confirm;
