import {
  Grid,
  TextField,
  Button,
  Box,
  Typography,
  FormControl,
  ListItemIcon,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import React, { useState, useContext, useEffect } from "react";
import { UserInputData } from "./Content";
import { useTranslation } from "react-i18next";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import enLocale from "date-fns/locale/en-US";
import jaLocale from "date-fns/locale/ja";
import { format, isValid as isDateValid } from "date-fns";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const localeMap = {
  en: enLocale,
  ja: jaLocale,
};

function filterWeekends(date) {
  // Return false if Saturday or Sunday
  return date.getDay() === 0 || date.getDay() === 6;
}

const today = new Date();
const startDate = new Date(new Date().setDate(today.getDate() + 30));

function Optional(props) {
  const { t } = useTranslation();
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    setError,
    clearErrors,
    formState: { isValid },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      cancellationDate: "",
      remarksInfomation: "",
    },
  });
  const [date, setDate] = useState(null);
  const { currentState, setCurrentState, isChecking } =
    useContext(UserInputData);

  const optional = currentState["Optional"];
  useEffect(() => {
    if (optional) {
      for (const k of Object.keys(getValues())) {
        setValue(k, optional[k], { shouldValidate: true });
      }
      switch (optional?.cancellationDate) {
        case "":
          setDate(null);
          break;
        default:
          const d = new Date(optional.cancellationDate);
          setDate(d);
          break;
      }
    }
  }, [optional, getValues, setValue]);

  const onSubmit = (action) => {
    if (action === "back") {
      props.handleBack();
    } else if (isChecking) {
      props.handleConfirm();
    } else {
      props.handleNext();
    }
    const data = getValues();
    setCurrentState({ ...currentState, Optional: data });
  };
  return (
    <>
      <Typography gutterBottom variant="h4" component="h2">
        {t("optionalLabel")}
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          name="cancellationDate"
          render={() => (
            <FormControl fullWidth={true}>
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={localeMap["ja"]}
              >
                <DesktopDatePicker
                  label={t("cancellationDateLabel")}
                  mask="____/__/__"
                  inputFormat="yyyy/MM/dd"
                  value={date}
                  renderInput={(params) => <TextField {...params} />}
                  onChange={(newValue) => {
                    if (isDateValid(newValue)) {
                      setValue(
                        "cancellationDate",
                        format(newValue, "yyyy/MM/dd")
                      );
                      setDate(newValue);
                    } else if (newValue === null) {
                      setValue("cancellationDate", "");
                      clearErrors("cancellationDate");
                      setDate(null);
                    }
                  }}
                  onError={(newValue) => {
                    if (newValue !== null) {
                      setError(
                        "cancellationDate",
                        { type: "focus" },
                        { shouldFocus: true }
                      );
                    }
                  }}
                  onAccept={(newValue) => {
                    clearErrors("cancellationDate");
                  }}
                  shouldDisableDate={filterWeekends}
                  minDate={startDate}
                  defaultCalendarMonth={startDate}
                />
              </LocalizationProvider>
            </FormControl>
          )}
        />
        <Box>
          <ListItemIcon>
            <ErrorOutlineIcon />
            <Typography sx={{ mt: 0.3, ml: 0.3 }} variant="body2">
              {t("cancellationDateNote")}
            </Typography>
          </ListItemIcon>
        </Box>
        <Controller
          control={control}
          name="remarksInfomation"
          render={({ field }) => (
            <TextField
              {...field}
              label={t("remarksInfomationLabel")}
              fullWidth
              margin="normal"
              rows={4}
              multiline
              variant="outlined"
              placeholder={t("remarksInfomationPlaceholder")}
            />
          )}
        />
        <Box mt={4} className="mb60">
          {isChecking ? (
            <Grid container spacing={1} justifyContent="flex-end">
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  type="button"
                  onClick={() => onSubmit("ok")}
                  disabled={!isValid}
                >
                  {t("okButtonLabel")}
                </Button>
              </Grid>
            </Grid>
          ) : (
            <Grid container spacing={1} justifyContent="space-between">
              <Grid item>
                <Button
                  variant="contained"
                  color="inherit"
                  onClick={() => onSubmit("back")}
                >
                  {t("backButtonLabel")}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={!isValid}
                >
                  {t("nextButtonLabel")}
                </Button>
              </Grid>
            </Grid>
          )}
        </Box>
      </form>
    </>
  );
}

export default Optional;
