import { Grid, Box } from "@mui/material";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CardLink from "components/card/CardLink";
import SecondTop from "components/top/SecondTop";
import ServiceAlert from "components/common/ServiceAlert";

function Top() {
  const { t, i18n } = useTranslation();
  const { lang } = useParams();
  useEffect(() => {
    if (lang === "en") {
      i18n.changeLanguage("en");
    } else {
      i18n.changeLanguage("ja");
    }
  }, [lang, i18n]);
  return (
    <>
      <ServiceAlert />
      <Box className="cmTop">
        <Grid
          container
          rowSpacing={2}
          columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 4, xl: 5 }}
          justifyContent="center"
          className="Top-row1"
        >
          <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
            <CardLink
              href="/add"
              icon={<AddCircleOutlineIcon fontSize="large" />}
              title={t("addCardText")}
              subTitle={t("addCardTextSecondary")}
            />
          </Grid>
        </Grid>
        <SecondTop />
      </Box>
    </>
  );
}

export default Top;
