import React from "react";
import { AppBar, Toolbar, Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import NotificationsPopover from "components/common/NotificationsPopover";
import Logo from "components/common/Logo";

const CmAppBar = styled(AppBar)({
  width: "100%",
  background: "black",
  color: "white",
  height: "60px",
  position: "static",
});

const Header = () => {
  return (
    <CmAppBar>
      <Toolbar>
        <Logo />
        <Box sx={{ flexGrow: 1 }} />
        <NotificationsPopover />
        <Box sx={{ mr: 2 }} />
      </Toolbar>
    </CmAppBar>
  );
};

export default Header;
