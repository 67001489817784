import {
  Card,
  Typography,
  CardActionArea,
  ButtonBase,
  CardMedia,
  CardContent,
} from '@mui/material';

function CardLink({ href, icon, title, subTitle }) {
  return (
    <>
      <Card>
        <CardActionArea>
          <ButtonBase
            sx={{
              display: 'block',
              textAlign: 'initial',
              color: '#D3B735',
            }}
            href={href}>
            <CardMedia className="icon">{icon}</CardMedia>
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                {title}
              </Typography>
              <Typography variant="body2" color="textPrimary" sx={{ fontSize: '1rem' }}>
                {subTitle}
              </Typography>
            </CardContent>
          </ButtonBase>
        </CardActionArea>
      </Card>
    </>
  );
}
export default CardLink;
