import "./App.css";
import Header from "components/common/Header";
import Footer from "components/common/Footer";
import Special from "components/special/Content";
import NewOrganizationsOption from "components/organizations/option/management/Content";
import NewMultiAccount from "components/multi-account/management/Content";
import AddSpam from "components/spam/add/Content";
import AddEcam from "components/ecam/add/Content";
import AddVoucher from "components/voucher/add/Content";
import AddOrganizationsOptionMember from "components/organizations/option/member/Content";
import AddMultiAccountMember from "components/multi-account/member/Content";
import AddMemberEcam from "components/ecam/organizations/member/Content";
import Plan from "components/plan/Content";
import DeleteCancel from "components/cancel/delete/Content";
import TransferCancel from "components/cancel/transfer/Content";
import Top from "components/top/Top";
import EcamTop from "components/top/EcamTop";
import VoucherTop from "components/top/VoucherTop";
import MultiAccountTop from "components/top/MultiAccountTop";
import OrganizationsTop from "components/top/OrganizationsTop";
import EcamOrganizationsTop from "components/top/EcamOrganizationsTop";
import ChangeCompany from "components/contract/change/Content";
import UpdateProject from "components/contract/update/project/Content";
import NotFound from "components/common/NotFound";
import { ProvideAuth } from "./auth.js";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import RequireAuth from "components/common/RequireAuth";
import Login from "components/common/Login";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { blueGrey } from "@mui/material/colors";
import { Box, CssBaseline } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      main: blueGrey["500"],
    },
  },
  mixins: {
    toolbar: {
      minHeight: 60,
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Box className="wrapper">
        <Header />
        <div style={{ padding: 30 }}>
          <ProvideAuth>
            <Router>
              <Routes>
                <Route element={<Special />} path="/special" />
                <Route element={<Top />} path="/" />
                <Route element={<OrganizationsTop />} path="/organizations" />
                <Route
                  element={<EcamOrganizationsTop />}
                  path="/ecam/organizations"
                />
                <Route
                  element={<MultiAccountTop />}
                  path="/organization/management"
                />
                <Route element={<EcamTop />} path="/ecam" />
                <Route element={<VoucherTop />} path="/voucher" />
                <Route element={<Login />} path="/login" />
                <Route
                  element={
                    <RequireAuth>
                      <Plan />
                    </RequireAuth>
                  }
                  path="/plan/modify"
                />
                <Route
                  element={
                    <RequireAuth>
                      <AddSpam />
                    </RequireAuth>
                  }
                  path="/add"
                />
                <Route
                  element={
                    <RequireAuth>
                      <AddEcam />
                    </RequireAuth>
                  }
                  path="/ecam/add"
                />
                <Route
                  element={
                    <RequireAuth>
                      <AddMemberEcam />
                    </RequireAuth>
                  }
                  path="/ecam/organizations/member"
                />
                <Route
                  element={
                    <RequireAuth>
                      <NewOrganizationsOption />
                    </RequireAuth>
                  }
                  path="/ecam/organizations/join"
                />
                <Route
                  element={
                    <RequireAuth>
                      <NewOrganizationsOption />
                    </RequireAuth>
                  }
                  path="/organizations/join"
                />
                <Route
                  element={
                    <RequireAuth>
                      <NewMultiAccount />
                    </RequireAuth>
                  }
                  path="/organization/management/join"
                />
                <Route
                  element={
                    <RequireAuth>
                      <AddOrganizationsOptionMember />
                    </RequireAuth>
                  }
                  path="/organizations/member"
                />
                <Route
                  element={
                    <RequireAuth>
                      <AddMultiAccountMember />
                    </RequireAuth>
                  }
                  path="/organization/management/member"
                />
                <Route
                  element={
                    <RequireAuth>
                      <DeleteCancel />
                    </RequireAuth>
                  }
                  path="/cancel/delete"
                />
                <Route
                  element={
                    <RequireAuth>
                      <TransferCancel />
                    </RequireAuth>
                  }
                  path="/cancel/transfer"
                />
                <Route
                  element={
                    <RequireAuth>
                      <ChangeCompany />
                    </RequireAuth>
                  }
                  path="/company/change"
                />
                <Route
                  element={
                    <RequireAuth>
                      <UpdateProject />
                    </RequireAuth>
                  }
                  path="/project/update"
                />
                <Route
                  element={
                    <RequireAuth>
                      <AddVoucher />
                    </RequireAuth>
                  }
                  path="/voucher/add"
                />
                <Route element={<NotFound />} path="/*" />
              </Routes>
            </Router>
          </ProvideAuth>
        </div>
        <Footer />
        <CssBaseline />
      </Box>
    </ThemeProvider>
  );
}
export default App;
