import { useForm, useWatch } from "react-hook-form";
import React, { useContext, useState, useEffect } from "react";
import { Grid, Button, Link, Box } from "@mui/material";
import { UserInputData } from "./Content";
import { useTranslation } from "react-i18next";
import ListItemIcon from "@mui/material/ListItemIcon";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import { FormProvider, RHFCheckbox } from "components/hook-form";
import FormHeader from "components/common/FormHeader";

const defaultValues = {
  termOfUse: false,
  handlingPersonalInformation: false,
};

function Agreement(props) {
  const { t } = useTranslation();
  const methods = useForm({
    defaultValues,
  });
  const { control, handleSubmit } = methods;
  const { currentState, setCurrentState } = useContext(UserInputData);
  const [displayNext, setDisplayNext] = useState(false);
  const watchHandlingPersonalInformation = useWatch({
    control,
    name: "handlingPersonalInformation",
  });
  const watchTermOfUse = useWatch({ control, name: "termOfUse" });
  useEffect(() => {
    if (watchHandlingPersonalInformation && watchTermOfUse) {
      setDisplayNext(true);
    } else {
      setDisplayNext(false);
    }
  }, [watchHandlingPersonalInformation, watchTermOfUse]);
  const onSubmit = (data) => {
    props.handleNext();
    setCurrentState({ ...currentState, Agreement: data });
  };
  return (
    <>
      <FormHeader
        label={t("agreementLabel")}
        description={t("agreementDescription")}
      />
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Box>
          <ListItemIcon>
            <ChevronRightOutlinedIcon />
            <Link
              target="_blank"
              href="https://docs.classmethod.jp/tos/cm_tos_members.pdf"
            >
              {t("tosLinkLabel")}
            </Link>
          </ListItemIcon>
        </Box>
        <Box>
          <ListItemIcon>
            <ChevronRightOutlinedIcon />
            <Link target="_blank" href="https://classmethod.jp/privacy/">
              {t("ppLinkLabel")}
            </Link>
          </ListItemIcon>
        </Box>
        <Box>
          <RHFCheckbox name="termOfUse" label={t("termOfUseLabel")} />
        </Box>
        <Box>
          <RHFCheckbox
            name="handlingPersonalInformation"
            label={t("handlingPersonalInformationLabel")}
          />
        </Box>
        <Box mt={4} className="mb60">
          <Grid container spacing={1} justifyContent="flex-end">
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={!displayNext}
              >
                {t("nextButtonLabel")}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </FormProvider>
    </>
  );
}

export default Agreement;
