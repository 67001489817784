import { Grid, Button, Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { useAuth } from "auth";

function Thanks({ currentState, handleReset }) {
  const { t } = useTranslation();
  const { user } = useAuth();
  let targetAddress = "";
  if ("Applicant" in currentState) {
    targetAddress = currentState.Applicant.applicantAddress;
  } else {
    targetAddress = user.info.email;
  }
  return (
    <>
      <Box sx={{ mt: "30px", mb: "40px" }} textAlign="center">
        <Typography variant="h4" component="div">
          {t("thanksLabel")}
        </Typography>
        <img
          src="/members_tate.svg"
          alt="Classmethod Members Logo"
          height="200"
        />
        <Typography
          variant="h5"
          gutterBottom
          component="div"
          className="requestId"
        >
          {t("requestIdLabel")} {currentState.requestId}
        </Typography>
        <Typography variant="body2" gutterBottom component="div">
          {targetAddress} {t("thanksNoteLabel")}
        </Typography>
      </Box>
      {typeof handleReset === "undefined" ? (
        ""
      ) : (
        <Box mt={4}>
          <Grid container spacing={1} justifyContent="center">
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                type="button"
                onClick={handleReset}
              >
                {t("continueButtonLabel")}
              </Button>
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
}

export default Thanks;
