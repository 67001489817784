import { useForm } from "react-hook-form";
import React, { useContext, useEffect } from "react";
import { UserInputData } from "./Content";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import CustomTooltip from "components/common/CustomTooltip";
import { FormProvider, RHFTextField } from "components/hook-form";
import FormHeader from "components/common/FormHeader";
import PaginationBox from "components/common/PaginationBox";
import { useOtherSchema } from "schemas/Others";

const defaultValues = {
  salesPersonName: "",
  campaignCode: "",
  remarksInfomation: "",
};

function Others(props) {
  const { t } = useTranslation();

  const otherSchema = useOtherSchema();
  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(otherSchema),
    defaultValues,
  });
  const {
    handleSubmit,
    setValue,
    getValues,
    formState: { isValid },
  } = methods;
  const { currentState, setCurrentState, isChecking } =
    useContext(UserInputData);

  const others = currentState["Others"];
  useEffect(() => {
    if (others) {
      for (const k of Object.keys(getValues())) {
        setValue(k, others[k], { shouldValidate: true });
      }
    }
  }, [others, getValues, setValue]);

  const onSubmit = (action) => {
    switch (action) {
      case "back":
        props.handleBack();
        break;
      case "ok":
        props.handleConfirm();
        break;
      default:
        props.handleNext();
    }
    const data = getValues();
    setCurrentState({ ...currentState, Others: data });
  };
  return (
    <>
      <FormHeader
        label={t("othersLabel")}
        description={t("othersDescription")}
      />
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <RHFTextField
          name="salesPersonName"
          label={t("salesPersonNameLabel")}
        />
        <CustomTooltip title={t("salesPersonNameTooltipTitle")} />
        <RHFTextField name="campaignCode" label={t("campaignCodeLabel")} />
        <CustomTooltip title={t("campaignCodeTooltipTitle")} />
        <RHFTextField
          name="remarksInfomation"
          label={t("remarksInfomationLabel")}
          placeholder={t("remarksInfomationPlaceholder")}
          rows={4}
          multiline
        />
        <CustomTooltip title={t("remarksInfomationTooltipTitle")} top={-75} />
        <PaginationBox
          isChecking={isChecking}
          isValid={isValid}
          onSubmit={onSubmit}
        />
      </FormProvider>
    </>
  );
}

export default Others;
