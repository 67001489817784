import { Typography } from "@mui/material";

export default function FormHeader({ label, description }) {
  return (
    <>
      <Typography gutterBottom variant="h4" component="h2">
        {label}
      </Typography>
      <Typography variant="body2" color="textSecondary">
        {description}
      </Typography>
    </>
  );
}
