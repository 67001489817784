import * as Yup from "yup";
import { useTranslation } from "react-i18next";

export function useOtherSchema() {
  const { t } = useTranslation();
  return Yup.object().shape({
    campaignCode: Yup.string().matches(
      /^[A-Z0-9]*$/,
      t("errorCampaignCodeFormat")
    ),
  });
}

export function useDesiredMonthSchema({ t }) {
  const today = new Date();
  var nextMonth;
  if (today.getMonth() === 11) {
    nextMonth = new Date(today.getFullYear() + 1, 0, 1);
  } else {
    nextMonth = new Date(today.getFullYear(), today.getMonth() + 1, 1);
  }
  const nextYear = new Date(today.getFullYear() + 1, today.getMonth(), 1);
  return {
    desiredMonthSchema: Yup.object().shape({
      desiredMonth: Yup.date()
        .nullable()
        .min(nextMonth, t("errorDesiredMonth"))
        .max(nextYear, t("errorDesiredMonth")),
    }),
    nextMonth: nextMonth,
    nextYear: nextYear,
  };
}
