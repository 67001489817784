import * as Yup from "yup";
import { useTranslation } from "react-i18next";

export function useContractSchema() {
  const { t } = useTranslation();
  return Yup.object().shape({
    changeAccounts: Yup.array().min(1, t("errorSelectCancelAccounts")),
    precautionsAssociatedOwnerChange: Yup.boolean().oneOf(
      [true],
      t("errorAccepted")
    ),
  });
}
