export default function Logo() {
  return (
    <a href="https://classmethod.jp/" target="_blank" rel="noopener noreferrer">
      <img
        src="/logo_classmethod.svg"
        alt="Classmethod, Inc."
        height="25"
      ></img>
    </a>
  );
}
