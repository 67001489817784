import { useForm, Controller, useWatch } from "react-hook-form";
import React, { useContext, useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import { MenuItem } from "@mui/material";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { UserInputData } from "./Content";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { useAuth } from "auth";
import Autocomplete from "@mui/material/Autocomplete";
import CustomTooltip from "components/common/CustomTooltip";
import ListItemIcon from "@mui/material/ListItemIcon";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { FormProvider, RHFTextField } from "components/hook-form";
import FormHeader from "components/common/FormHeader";
import PaginationBox from "components/common/PaginationBox";
import { usePlanSchema } from "schemas/Plan";

const defaultValues = {
  projectId: "",
  currentSubscriptionPlan: "",
  subscriptionPlan: "",
};

function getAvailablePlans(currentPlan) {
  switch (currentPlan) {
    // V2_VOUCHER は、一律5% or EC2CDN プランへ変更可能
    case "V2_VOUCHER":
      return ["V2_MEMBERS", "V2_PREMIUM", "START", "MEMBERS"];
    // V2_MEMBERS or START は、plans から同じプランを除いた契約に変更可能
    case "V2_MEMBERS":
    case "START":
      const plans = ["V2_MEMBERS", "V2_PREMIUM", "START", "MEMBERS"];
      return plans.filter((n) => n !== currentPlan);
    // 原則ダウングレード禁止のため、既存契約がプレミアムならば変更可能なプランはプレミアムのみとする
    case "MEMBERS":
      return ["V2_PREMIUM"];
    case "V2_PREMIUM":
      return ["MEMBERS"];
    // 2024/4 中旬に追加予定の一律5%（クレカ支払い）プランは、アップグレードのみサポート
    case "FLATRATE_DISCOUNT_2":
      return ["FLATRATE_DISCOUNT_2_PREMIUM"];
    // 専用Payer および共有Payer の ECAM契約はアップグレードのみ許容し、ダウングレードおよび他のプランへの変更は、申込みフォームからは非サポート
    case "ORG_CDN_DISCOUNT":
      return ["ORG_CDN_DISCOUNT_PREMIUM"];
    case "ORG_PRIVATE_DISCOUNT":
      return ["ORG_PRIVATE_DISCOUNT_PREMIUM"];
    case "ORGANIZATION":
      return ["ORGANIZATION_PREMIUM"];
    case "ORG_FLATRATE_DISCOUNT":
      return ["ORG_FLATRATE_DISCOUNT_PREMIUM"];
    case "ORG_FLATRATE_DISCOUNT_2":
      return ["ORG_FLATRATE_DISCOUNT_2_PREMIUM"];
    case "ORG_FLATRATE_DISCOUNT_4":
      return ["ORG_FLATRATE_DISCOUNT_4_PREMIUM"];
    case "ORG_FLATRATE_DISCOUNT_5":
      return ["ORG_FLATRATE_DISCOUNT_5_PREMIUM"];
    case "CDN_DISCOUNT":
      return ["CDN_DISCOUNT_PREMIUM"];
    case "FLATRATE_DISCOUNT_6":
      return ["FLATRATE_DISCOUNT_6_PREMIUM"];
    case "FLATRATE_DISCOUNT_7":
      return ["FLATRATE_DISCOUNT_7_PREMIUM"];
    case "FLATRATE_DISCOUNT_8":
      return ["FLATRATE_DISCOUNT_8_PREMIUM"];
    case "FLATRATE_DISCOUNT_9":
      return ["FLATRATE_DISCOUNT_9_PREMIUM"];
    case "FLATRATE_DISCOUNT_10":
      return ["FLATRATE_DISCOUNT_10_PREMIUM"];
    case "FLATRATE_DISCOUNT_11":
      return ["FLATRATE_DISCOUNT_11_PREMIUM"];
    case "FLATRATE_DISCOUNT_12":
      return ["FLATRATE_DISCOUNT_12_PREMIUM"];
    case "ORG_PRIVATE_DISCOUNT_1":
      return ["ORG_PRIVATE_DISCOUNT_1_PREMIUM"];
    case "ORG_PRIVATE_DISCOUNT_2":
      return ["ORG_PRIVATE_DISCOUNT_2_PREMIUM"];
    case "ORG_PRIVATE_DISCOUNT_3":
      return ["ORG_PRIVATE_DISCOUNT_3_PREMIUM"];
    // 以下、サポート対象外
    case "ORG_CDN_DISCOUNT_PREMIUM":
    case "ORG_PRIVATE_DISCOUNT_PREMIUM":
    case "ORGANIZATION_PREMIUM":
    case "ORG_FLATRATE_DISCOUNT_PREMIUM":
    case "ORG_FLATRATE_DISCOUNT_4_PREMIUM":
    case "ORG_FLATRATE_DISCOUNT_5_PREMIUM":
    case "CDN_DISCOUNT_PREMIUM":
    case "FLATRATE_DISCOUNT_6_PREMIUM":
    case "FLATRATE_DISCOUNT_7_PREMIUM":
    case "FLATRATE_DISCOUNT_8_PREMIUM":
    case "FLATRATE_DISCOUNT_9_PREMIUM":
    case "FLATRATE_DISCOUNT_10_PREMIUM":
    case "FLATRATE_DISCOUNT_11_PREMIUM":
    case "FLATRATE_DISCOUNT_12_PREMIUM":
    case "ORG_PRIVATE_DISCOUNT_1_PREMIUM":
    case "ORG_PRIVATE_DISCOUNT_2_PREMIUM":
    case "ORG_PRIVATE_DISCOUNT_3_PREMIUM":
    case "ORG_PREMIUM_V3":
    case "ORG_MEMBERS_V3":
    case "V3_VOUCHER":
    case "V3_PREMIUM":
    case "V3_MEMBERS":
    case "V3_DISCOUNT_4_PREMIUM":
    case "V3_DISCOUNT_4":
    case "ORG_PRIVATE_DISCOUNT_8":
    case "ORG_PRIVATE_DISCOUNT_8_PREMIUM":
    case "UNIVCOOP_VOUCHER":
    case "FLATRATE_DISCOUNT_2_PREMIUM":
      return [];
    default:
      return [];
  }
}

function Plan(props) {
  const { projects } = useAuth();
  const { t } = useTranslation();

  const planSchema = usePlanSchema();
  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(planSchema),
    defaultValues,
  });
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors, isValid },
  } = methods;
  const { currentState, setCurrentState, isChecking } =
    useContext(UserInputData);
  const [availablePlans, setAvailablePlans] = useState([]);
  const [currentProjectId, setCurrentProjectId] = useState(0);

  const watchProjectId = useWatch({ control, name: "projectId" });
  useEffect(() => {
    if (watchProjectId > 0) {
      setCurrentProjectId(watchProjectId);
      if (watchProjectId !== currentProjectId) {
        for (var i in projects) {
          if (projects[i]["id"] === watchProjectId) {
            var projectType = projects[i]["projectType"];
            var planLabel = t(projectType + "Label");
            setCurrentState({
              ...currentState,
              currentPlan: [{ id: projectType, name: planLabel }],
            });
            setValue("currentSubscriptionPlan", planLabel);
            setAvailablePlans(getAvailablePlans(projectType));
          }
        }
      }
    }
  }, [
    watchProjectId,
    currentState,
    projects,
    setCurrentState,
    setValue,
    t,
    currentProjectId,
  ]);
  const onSubmit = (action) => {
    switch (action) {
      case "back":
        props.handleBack();
        break;
      case "ok":
        props.handleConfirm();
        break;
      default:
        props.handleNext();
    }
    const data = getValues();
    setCurrentState({ ...currentState, Plan: data });
  };
  return (
    <>
      <FormHeader label={t("planLabel")} description={t("planDescription")} />
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          name="projectId"
          render={({ props }) => (
            <Autocomplete
              options={projects}
              getOptionLabel={(project) => project.name}
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option.id}>
                    {option.name}
                  </li>
                );
              }}
              renderInput={(props) => (
                <TextField
                  {...props}
                  label={t("projectIdLabel")}
                  error={errors.projectId ? true : false}
                  helperText={errors.projectId?.message}
                  fullWidth
                  margin="normal"
                  key={props.id}
                />
              )}
              onChange={(event, value) => {
                if (value !== null) {
                  setValue("projectId", value.id, { shouldValidate: true });
                }
              }}
            />
          )}
        />
        <CustomTooltip title={t("projectIdPlanTooltipTitle")} />
        <RHFTextField
          name="currentSubscriptionPlan"
          label={t("currentSubscriptionPlanLabel")}
          disabled={true}
        />
        <Controller
          control={control}
          name="subscriptionPlan"
          render={({ field }) => (
            <TextField
              {...field}
              label={t("subscriptionChangedPlanLabel")}
              error={errors.subscriptionPlan ? true : false}
              helperText={errors.subscriptionPlan?.message}
              fullWidth
              margin="normal"
              id="subscriptionPlan"
              select
            >
              {availablePlans.map((i) => (
                <MenuItem key={String(i)} value={String(i)}>
                  {t(String(i) + "Label")}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
        <CustomTooltip title={t("subscriptionPlanChangedTooltipTitle")} />
        <Box>
          <ListItemIcon>
            <ErrorOutlineIcon />
            <Typography sx={{ mt: 0.3, ml: 0.3 }} variant="body2">
              {t("changedPlanNote2")}
            </Typography>
          </ListItemIcon>
        </Box>
        <PaginationBox
          isChecking={isChecking}
          isValid={isValid}
          onSubmit={onSubmit}
        />
      </FormProvider>
    </>
  );
}

export default Plan;
