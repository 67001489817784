import * as Yup from "yup";
import { useTranslation } from "react-i18next";

export function useAddBillingSchema() {
  const { t } = useTranslation();
  return Yup.object().shape({
    billingMethod: Yup.string().oneOf(
      ["existingProject"],
      t("errorSelectBillingMethod")
    ),
    projectName: Yup.string().when("billingMethod", {
      is: (val) => val === "newProject",
      then: Yup.string()
        .required(t("errorRequired"))
        .max(32, t("errorProjectNameMaxLimit"))
        .matches(/^[a-zA-Z0-9!-/:-@¥[-`{-~ ]*$/, t("errorProjectNameFormat")),
      otherwise: Yup.string().notRequired(),
    }),
    billingOption: Yup.string().when("billingMethod", {
      is: (val) => val === "newProject",
      then: Yup.string().oneOf(["emailOnly"], t("errorSelectBillingOption")),
      otherwise: Yup.string().notRequired(),
    }),
    paymentMethod: Yup.string().when("billingMethod", {
      is: (val) => val === "newProject",
      then: Yup.string().oneOf(["bankTransfer"], t("errorSelectPaymentMethod")),
      otherwise: Yup.string().notRequired(),
    }),
    subscriptionPlan: Yup.string().when("billingMethod", {
      is: (val) => val === "newProject",
      then: Yup.string()
        .required(t("errorRequired"))
        .oneOf(["V2_VOUCHER"], t("errorSelectSubscriptionPlan")),
      otherwise: Yup.string().notRequired(),
    }),
    paymentCurrency: Yup.string().when("billingMethod", {
      is: (val) => val === "newProject",
      then: Yup.string().oneOf(["JPY"], t("errorSelectPaymentCurrency")),
      otherwise: Yup.string().notRequired(),
    }),
    invoiceLanguage: Yup.string().when("billingMethod", {
      is: (val) => val === "newProject",
      then: Yup.string().oneOf(["ja"], t("errorSelectInvoiceLanguage")),
      otherwise: Yup.string().notRequired(),
    }),
    invoicePersonFirstName: Yup.string().when("billingMethod", {
      is: (val) => val === "newProject",
      then: Yup.string().required(t("errorRequired")),
      otherwise: Yup.string().notRequired(),
    }),
    invoicePersonLastName: Yup.string().when("billingMethod", {
      is: (val) => val === "newProject",
      then: Yup.string().required(t("errorRequired")),
      otherwise: Yup.string().notRequired(),
    }),
    invoiceMailAddress: Yup.string().when("billingMethod", {
      is: (val) => val === "newProject",
      then: Yup.string().required(t("errorRequired")).email(t("errorEmail")),
      otherwise: Yup.string().notRequired(),
    }),
    agreementOnPaymentTerms: Yup.boolean()
      .when("billingMethod", {
        is: (val) => val === "newProject",
        then: Yup.boolean().oneOf([true], t("errorAccepted")),
      })
      .when("billingMethod", {
        is: (val) => val === "existingProject",
        then: Yup.boolean().oneOf([false], t("errorAccepted")),
      }),
    projectId: Yup.string().when("billingMethod", {
      is: (val) => val === "existingProject",
      then: Yup.string().required(t("errorRequired")),
      otherwise: Yup.string().notRequired(),
    }),
  });
}
